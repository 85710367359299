import { Draggable, Droppable } from "@hello-pangea/dnd";
import { useEffect, useState } from "react";

import { FieldComponentType } from "@smart/bridge-intake-components-dom";
import { MeetingType } from "@smart/bridge-types-basic";
import {
  EditableText,
  ProgressBar,
  ProgressCircle,
} from "@smart/itops-sb-design-system-dom";
import { DeepPartial, localTimeZoneCode } from "@smart/itops-utils-basic";
import {
  useDeleteField,
  useDeleteGroup,
  useUpdateAIUserFeedback,
  useUpdateGroup,
} from "@smart/manage-gql-client-dom";

import { Field } from "./field";
import { GeneratingForm } from "./generating-form";
import { Group } from "./group";
import { InitialDroppable, Separator } from "./separator";
import { FormLoadingStatus } from "../../create-form/creation-status";
import {
  sectionItemContainerDroppablePrefix,
  sectionItemsDroppableId,
} from "../constants";
import { ConditionsButton } from "../edit-view/condition/conditions-button";
import { useUpdateData } from "../hooks";
import {
  ConditionActions,
  EditableSection,
  FieldTemplate,
  GqlFieldValues,
  GqlForm,
  GqlMatterLayout,
  GqlMatterType,
  GqlSectionItem,
  LoadMatterFields,
  QuestionEditMode,
  isSectionItemField,
} from "../types";
import { getDefaultAvailability } from "../utils";

type QuestionsProps = {
  sectionItems: GqlSectionItem[];
  loading: {
    sections: boolean;
    groups: boolean;
    fields: boolean;
  };
  form?: GqlForm | null;
  section?: EditableSection;
  mode?: QuestionEditMode;
  editingItemUri?: string;
  updatingItems: Record<string, boolean | undefined>;
  mergeUpdateField: ReturnType<typeof useUpdateData>["mergeUpdateField"];
  updateGroup: ReturnType<typeof useUpdateGroup>[0];
  deleteField: ReturnType<typeof useDeleteField>[0];
  deleteGroup: ReturnType<typeof useDeleteGroup>[0];
  updateSection: (
    section: EditableSection,
    values: { title: string },
  ) => Promise<void>;
  onUpdatingFieldDone: () => void;
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  updateAIUserFeedback: ReturnType<typeof useUpdateAIUserFeedback>[0];
  loadMatterFields: LoadMatterFields;
  loadingStatus: FormLoadingStatus;
  creationStatus?: string | null;
  conditionActions: ConditionActions;
  setSelectedFieldToMap: React.Dispatch<
    React.SetStateAction<GqlFieldValues | undefined>
  >;
  isValidSection: boolean;
  excludingFieldTemplates: FieldTemplate[];
};

const Questions = ({
  form,
  section,
  sectionItems,
  loading,
  mode,
  editingItemUri,
  updatingItems,
  mergeUpdateField,
  updateGroup,
  deleteField,
  updateSection,
  onUpdatingFieldDone,
  matterLayouts,
  matterTypes,
  updateAIUserFeedback,
  loadMatterFields,
  deleteGroup,
  loadingStatus,
  creationStatus,
  conditionActions,
  setSelectedFieldToMap,
  isValidSection,
  excludingFieldTemplates,
}: QuestionsProps) => {
  const [editingTitle, setEditingTitle] = useState(
    section?.values?.title || "Section",
  );

  useEffect(() => {
    setEditingTitle(section?.values?.title || "Section");
  }, [section]);

  const onUpdateField = async ({
    field,
    updated,
  }: {
    field: GqlFieldValues;
    updated: DeepPartial<GqlFieldValues>;
  }) => {
    await mergeUpdateField({ current: field, incoming: updated }).catch(
      console.error,
    );
    onUpdatingFieldDone();
  };

  const onDeleteField = async (field: GqlFieldValues) => {
    await deleteField({
      variables: {
        uri: field.uri,
        sectionUri: field.sectionUri,
        formUri: field.formUri,
      },
    }).catch(console.error);
    onUpdatingFieldDone();
  };

  const onFieldTypeChange = async ({
    field,
    newFieldType,
  }: {
    field: GqlFieldValues;
    newFieldType: FieldComponentType;
  }) => {
    if (
      newFieldType === "group" ||
      (newFieldType === field.type && !field.layout && !field.field)
    ) {
      return;
    }

    if (newFieldType === "mapped") {
      setSelectedFieldToMap(field);
      return;
    }

    const initialValues =
      newFieldType === "appointment"
        ? {
            duration: 15,
            availability: getDefaultAvailability(),
            timezone: localTimeZoneCode,
            meetingType: "inPerson" as MeetingType,
          }
        : undefined;

    await mergeUpdateField({
      current: field,
      incoming: {
        options:
          (newFieldType === "choice" || newFieldType === "checkbox") &&
          field.options
            ? field.options.map((option) => ({
                label: option.label,
                value: option.value,
              }))
            : [],
        type: newFieldType,
        layout: undefined,
        field: undefined,
        links: undefined,
        ...initialValues,
      },
    }).catch(console.error);
  };

  const disabled =
    loadingStatus === "checking" ||
    loadingStatus === "timedOut" ||
    form?.aiUserFeedback?.status === "generating" ||
    form?.aiUserFeedback?.status === "generated";

  return (
    <div
      data-testid="edit-questions"
      className="flex-1 bg-neutral-50  pt-0 relative overflow-auto w-full "
    >
      {section && updatingItems[section.uri] && (
        <ProgressBar className="h-[0.2rem]" />
      )}
      <div className="p-8">
        <GeneratingForm
          updating={
            !!form?.aiUserFeedback && updatingItems[form.aiUserFeedback.uri]
          }
          title={form?.formTitle || ""}
          loadingStatus={loadingStatus}
          creationStatus={creationStatus}
          aiUserFeedback={form?.aiUserFeedback}
          updateAIUserFeedback={updateAIUserFeedback}
          form={form}
        />
        <div
          className="aria-disabled:hidden flex justify-between items-center max-w-screen-readable mx-auto mt-8 mb-4"
          aria-disabled={disabled}
        >
          <div className="font-poppins">
            <EditableText
              dataTestId="section-title-edit"
              text={editingTitle}
              onChange={setEditingTitle}
              onComplete={async () => {
                if (section)
                  await updateSection(section, { title: editingTitle || "" });
              }}
              disabled={disabled}
              loading={loading.sections}
              fontSize="h3"
              fontWeight="semibold"
              ignoreSubmitOnBlur
            />
          </div>
          {section && conditionActions.checkHasConditions(section.uri) && (
            <ConditionsButton
              numberOfConditions={conditionActions.getNumberOfConditions(
                section.uri,
              )}
              onClick={() =>
                section.values &&
                conditionActions.setEditingCondition(section.values)
              }
              dataTestId="section-conditions-button"
              size="small"
            />
          )}
        </div>
        <div
          aria-disabled={disabled}
          className="flex gap-8 flex-col aria-disabled:pointer-events-none aria-disabled:opacity-50"
        >
          <Droppable droppableId={sectionItemsDroppableId} type="SECTION">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sectionItems.length ? (
                  <Separator
                    index={0}
                    isFieldReordering={mode === "reordering"}
                  />
                ) : (
                  <InitialDroppable index={0} />
                )}

                {(loading.groups || loading.fields) && (
                  <ProgressCircle className="my-8 mx-auto" size="medium" />
                )}
                {sectionItems.map((item, index) => (
                  <div key={item.uri} data-testid="section-item-container">
                    <Droppable
                      droppableId={`${sectionItemContainerDroppablePrefix}-${item.uri}`}
                      type="GROUP"
                    >
                      {(droppableContainerProvided) => (
                        <div
                          ref={droppableContainerProvided.innerRef}
                          {...droppableContainerProvided.droppableProps}
                        >
                          <Draggable draggableId={item.uri} index={index}>
                            {(draggableProvided, snapshot) =>
                              isSectionItemField(item) ? (
                                <Field
                                  index={index}
                                  item={item}
                                  mode={mode}
                                  matterTypes={matterTypes}
                                  onUpdateField={onUpdateField}
                                  onDeleteField={onDeleteField}
                                  matterLayouts={matterLayouts}
                                  isDragging={snapshot.isDragging}
                                  loadMatterFields={loadMatterFields}
                                  draggableProvided={draggableProvided}
                                  loading={updatingItems[item.uri]}
                                  isEditing={item.uri === editingItemUri}
                                  conditionActions={conditionActions}
                                  onFieldTypeChange={onFieldTypeChange}
                                  isDropAnimating={snapshot.isDropAnimating}
                                  isValidSection={isValidSection}
                                  excludingFieldTemplates={
                                    excludingFieldTemplates
                                  }
                                />
                              ) : (
                                <Group
                                  mode={mode}
                                  index={index}
                                  item={item}
                                  fields={item.fields}
                                  matterTypes={matterTypes}
                                  updateGroup={updateGroup}
                                  deleteGroup={deleteGroup}
                                  onUpdateField={onUpdateField}
                                  onDeleteField={onDeleteField}
                                  matterLayouts={matterLayouts}
                                  isDragging={snapshot.isDragging}
                                  editingItemUri={editingItemUri}
                                  loadMatterFields={loadMatterFields}
                                  updatingItems={updatingItems}
                                  draggableProvided={draggableProvided}
                                  loading={updatingItems[item.uri]}
                                  isEditing={item.uri === editingItemUri}
                                  conditionActions={conditionActions}
                                  onFieldTypeChange={onFieldTypeChange}
                                  isDropAnimating={snapshot.isDropAnimating}
                                  isValidSection={isValidSection}
                                  excludingFieldTemplates={
                                    excludingFieldTemplates
                                  }
                                />
                              )
                            }
                          </Draggable>
                          {droppableContainerProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </div>
  );
};

export { Questions };
