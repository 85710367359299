import {
  FormCategory,
  FormNS,
  formCategoryValue,
} from "@smart/bridge-types-basic";
import { GqlStatusOverlay } from "@smart/itops-components-dom";
import {
  useParamsWithDefault,
  useSearchWithDefault,
} from "@smart/itops-hooks-dom";
import { removeKeys } from "@smart/itops-utils-basic";
import {
  useAddFormMatterType,
  useLoadMatterLayouts,
  useLoadMatterTypes,
  useLoadSetting,
  useLoadTeam,
  useLoadTeamForms,
  useQueryMatterFields,
  useUpdateForm,
} from "@smart/manage-gql-client-dom";
import { useManageFormsContext, useUser } from "@smart/manage-hooks-dom";

import { EditFormV2 } from "../../components/embedded/form-builder";
import { useLoadFormWithLoadingStatus } from "../../components/embedded/form-builder/create-form/creation-status";
import { GqlTeam } from "../../components/team/types";

const EditForm = () => {
  const { id: formId } = useParamsWithDefault((p) => ({
    id: p.id || "",
  }));
  const { matterTypeIds, name, category } = useSearchWithDefault((p) => {
    const matterTypeIdsParam = p.get("matterTypeIds");

    return {
      matterTypeIds: matterTypeIdsParam ? matterTypeIdsParam.split(",") : [],
      name: p.get("name") || "",
      category: p.get("category") || "",
    };
  });
  const formCategory = formCategoryValue.includes(category as FormCategory)
    ? (category as FormCategory)
    : undefined;

  const { actingTeam } = useManageFormsContext();
  const team = useLoadTeam({});
  const formUri = FormNS.generateUri(formId);
  const { loadingStatus, form, sections, groups, fields } =
    useLoadFormWithLoadingStatus({
      formUri,
      shouldCheck: !matterTypeIds || !name,
    });
  const { user } = useUser();
  const matterTypes = useLoadMatterTypes(
    { matterTypeIds, type: formCategory! },
    {
      skip:
        !matterTypeIds.length ||
        form.loading ||
        !!form.result ||
        !formCategory ||
        !user,
    },
  );

  const availableMatterTypeIds = form.result
    ? form.result.rawForm.values.matterTypes.map(({ id }) => id)
    : matterTypeIds;
  const matterLayouts = useLoadMatterLayouts(
    {
      matterTypeIds: availableMatterTypeIds,
    },
    { skip: !availableMatterTypeIds.length || form.loading || !user },
  );
  const loadMatterFields = useQueryMatterFields({
    fetchPolicy: "network-only",
  });

  const [updateForm] = useUpdateForm();
  const [addFormMatterType] = useAddFormMatterType();
  const setting = useLoadSetting({});
  const teamForms = useLoadTeamForms({ teamUri: actingTeam?.uri });

  const createForm = async () => {
    if (matterTypes.result?.length && formCategory) {
      await updateForm({
        variables: {
          uri: formUri,
          fields: {
            title: name,
            category: formCategory,
            source: "manual",
            response: "",
          },
        },
      });

      for (const matterType of matterTypes.result) {
        await addFormMatterType({
          variables: {
            formUri,
            matterType: removeKeys(matterType, ["__typename"]),
          },
        });
      }
    }
  };

  const loading =
    (form.loading && !form.data) ||
    matterTypes.loading ||
    (sections.loading && !sections.data) ||
    (groups.loading && !groups.data) ||
    (fields.loading && !fields.data) ||
    setting.loading ||
    teamForms.loading;
  const error = form.error || matterTypes.error || matterLayouts.error;

  if (loading || error)
    return <GqlStatusOverlay loading={loading} error={error} />;

  return (
    <EditFormV2
      formUri={formUri}
      team={
        actingTeam
          ? ({ uri: actingTeam.uri, values: actingTeam } as GqlTeam)
          : team.result
      }
      setting={setting.result}
      form={form.result}
      sections={sections.result}
      groups={groups.result}
      fields={fields.result}
      title={name}
      category={formCategory}
      loadingStatus={loadingStatus}
      matterTypes={
        matterTypes.result?.map((mt) => removeKeys(mt, ["__typename"])) ||
        form.result?.rawForm.values.matterTypes
      }
      matterLayouts={matterLayouts.result}
      teamForms={teamForms.result?.map((f) => ({
        uri: f.uri,
        teamUri: f.values.teamUri,
        formTitle: f.values.title,
      }))}
      loadMatterFields={loadMatterFields}
      createForm={createForm}
      loading={{
        team: team.loading,
        form: form.loading,
        matterTypes: matterTypes.loading,
        sections: sections.loading,
        groups: groups.loading,
        fields: fields.loading,
        matterLayouts: matterLayouts.loading,
      }}
    />
  );
};

export { EditForm };
