import clsx from "clsx";

import { FieldComponentType } from "@smart/bridge-intake-components-dom";
import { Avatar, ContextMenu } from "@smart/itops-sb-design-system-dom";

import {
  fieldComponentGroups,
  fieldComponentIcons,
  fieldComponentNames,
} from "../../constants";
import {
  FieldTemplate,
  GqlFieldValues,
  GqlGroupValues,
  GqlMatterLayout,
  GqlMatterType,
  LoadMatterFields,
} from "../../types";
import { MappedFieldPathDisplay } from "../mapped-field-path-display";

const FieldTypeDisplay = ({
  fieldType,
  isMapped,
}: {
  fieldType: FieldComponentType;
  isMapped: boolean;
}) => (
  <div className={clsx("flex items-center gap-small rounded")}>
    <Avatar
      size={24}
      icon={
        isMapped
          ? fieldComponentIcons.mapped.name
          : fieldComponentIcons[fieldType].name
      }
      bgColor={
        isMapped
          ? fieldComponentIcons.mapped.bgColor
          : fieldComponentIcons[fieldType].bgColor
      }
    />
    <span className="text-ui font-semibold">
      {fieldComponentNames[fieldType]}
    </span>
  </div>
);

type FieldTypeIndicatorProps = {
  layout?: GqlFieldValues["layout"];
  matterField?: GqlFieldValues["field"];
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  loadMatterFields: LoadMatterFields;
  groupLayout?: GqlGroupValues["layout"] | null;
  groupMatterField?: GqlGroupValues["field"] | null;
  onChangeFieldType: (newFieldType: FieldComponentType) => Promise<void>;
  excludingFieldTemplates: FieldTemplate[];
  fieldType: FieldComponentType;
};

const FieldTypeIndicator = ({
  matterField,
  layout,
  matterLayouts,
  matterTypes,
  loadMatterFields,
  groupMatterField,
  groupLayout,
  onChangeFieldType,
  excludingFieldTemplates,
  fieldType,
}: FieldTypeIndicatorProps) => {
  const isMapped = !!(matterField && layout);

  const menu = (
    <ContextMenu
      id="change-field-type"
      placement="bottom-start"
      fallbackPlacements={["top-start"]}
      button={{}}
      buttonOverride={
        <FieldTypeDisplay fieldType={fieldType} isMapped={isMapped} />
      }
      items={fieldComponentGroups
        .flatMap((group) => group.components)
        .filter(
          (c) =>
            c.type !== "group" &&
            !excludingFieldTemplates.find(({ type }) => type === c.type),
        )
        .map((c) => ({
          label: fieldComponentNames[c.type],
          labelOverride: (
            <FieldTypeDisplay fieldType={c.type} isMapped={false} />
          ),
          onClick: async () => onChangeFieldType(c.type),
        }))}
    />
  );

  return (
    <div className="flex items-center gap-4">
      {menu}
      {isMapped && (
        <MappedFieldPathDisplay
          field={matterField}
          layout={layout}
          matterLayouts={matterLayouts}
          matterTypes={matterTypes}
          loadMatterFields={loadMatterFields}
          groupMatterField={groupMatterField}
          groupLayout={groupLayout}
        />
      )}
    </div>
  );
};

export { FieldTypeIndicator, FieldTypeIndicatorProps };
