import { useMemo } from "react";

import { Avatar } from "@smart/itops-sb-design-system-dom";
import { jsonParseOrReturn } from "@smart/itops-utils-basic";

import { ConditionLink } from "./condition-link";
import { getOptions } from "./condition-type";
import { ConditionItem } from "./utils";
import { Condition } from "../conditions";

const conditionListItemClasses =
  "my-[0.6rem] mx-0 flex flex-row flex-nowrap items-center justify-start gap-[0.4rem]";

const conditionOperationClasses = "flex-none whitespace-nowrap";

export type ConditionListItemProps = {
  condition: Condition;
  setCurrentField: (field: ConditionItem | null) => void;
};

export const AffectedBy = ({
  condition,
  setCurrentField,
}: ConditionListItemProps) => {
  const conditionOperation = useMemo(
    () =>
      getOptions(condition.affectingField.type).find(
        (option) => option.condition === condition.condition,
      )?.displayText,
    [condition.affectingField.type, condition.condition],
  );
  const conditionValue = useMemo(() => {
    const { type, options } = condition.affectingField;
    const getLabel = (v: string) =>
      options.find((o) => o.value === v)?.label || v;

    try {
      if (type === "checkbox") {
        const value = jsonParseOrReturn(condition.value);
        return value
          .map(getLabel)
          .join(condition.condition === "in" ? " or " : " and ");
      }

      if (type === "choice" && condition.condition !== "equalTo") {
        const value = jsonParseOrReturn(condition.value);
        return value.map(getLabel).join(" or ");
      }

      if (type === "choice") {
        return getLabel(condition.value);
      }
    } catch (e) {
      console.error("Error parsing condition value", e);
    }

    return condition.value;
  }, [
    condition.affectingField.type,
    condition.affectingField.options,
    condition.condition,
    condition.value,
  ]);

  return (
    <div
      className={conditionListItemClasses}
      data-testid="affected-by-condition"
    >
      <ConditionLink
        field={condition.affectingField}
        setCurrentField={setCurrentField}
      />
      <span className={conditionOperationClasses}>{conditionOperation}</span>
      <Avatar
        size={24}
        text={conditionValue}
        bgColor="bg-cyan-240"
        className="w-fit px-4 max-w-[50%]"
      />
    </div>
  );
};

export const Effecting = ({
  condition,
  setCurrentField,
}: ConditionListItemProps) => (
  <div className={conditionListItemClasses} data-testid="effecting-condition">
    <Avatar
      size={24}
      text={condition.hide ? "Hide" : "Show"}
      bgColor="bg-cyan-240"
      className="w-fit px-4"
    />
    <ConditionLink
      field={condition.affectedItem}
      setCurrentField={setCurrentField}
    />
  </div>
);
