import clsx from "clsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { TemplateResponseData } from "@smart/bridge-templates-basic";
import { Button, Switch } from "@smart/itops-sb-design-system-dom";
import { extractId } from "@smart/itops-types-basic";

import { ClearForm } from "./clear-form";
import { FieldValidationToggle } from "./field-validation-toggle";
import { Settings } from "./settings";

type ActionsProps = {
  formUri: string;
  isMatterSpecific: boolean;
  isFormActive: boolean | undefined;
  canActivate?: boolean;
  canUpdate?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onActiveToggleClick: () => Promise<void>;
  confirmationMessage: string;
  saveConfirmationMessage: (value?: string) => Promise<void>;
  defaultConfirmationMessage?: string | null;
  placeholderData: Partial<TemplateResponseData>;
  isPreview: boolean;
  previewErrorToggle: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  clearIntakeForm: () => void;
};

const Actions = ({
  formUri,
  isMatterSpecific,
  isFormActive,
  canActivate,
  disabled,
  loading,
  canUpdate,
  onActiveToggleClick,
  confirmationMessage,
  saveConfirmationMessage,
  defaultConfirmationMessage,
  placeholderData,
  isPreview,
  previewErrorToggle,
  clearIntakeForm,
}: ActionsProps) => {
  const navigate = useNavigate();
  const [settingsModal, showSettingsModal] = useState(false);
  const [clearFormModal, showClearFormModal] = useState(false);

  return (
    <div
      className={clsx(
        "flex justify-end items-center gap-large flex-1",
        isMatterSpecific && "gap-small",
      )}
    >
      {isPreview ? (
        <div className="flex items-center gap-large">
          <FieldValidationToggle
            toggled={!previewErrorToggle[0]}
            onClick={() => previewErrorToggle[1](!previewErrorToggle[0])}
          />
          <Button
            text="Clear form"
            variant="secondaryDefault"
            type="reset"
            onClick={() => showClearFormModal(true)}
          />
          <ClearForm
            onSubmit={() => {
              clearIntakeForm();
              showClearFormModal(false);
            }}
            onClose={() => showClearFormModal(false)}
            open={clearFormModal}
          />
        </div>
      ) : (
        <>
          {!isMatterSpecific && (
            <div className="flex gap-small">
              <Switch
                dataTestId="active-toggle"
                disabled={
                  !canActivate || disabled || (loading && !isFormActive)
                }
                toggled={!!isFormActive}
                onClick={() => onActiveToggleClick()}
              />
              <div className="text-ui">Active</div>
            </div>
          )}
          {canUpdate && (
            <Button
              onClick={() => showSettingsModal(true)}
              text="Settings"
              variant="secondaryDefault"
              disabled={disabled}
              leftIcon="regularGear"
              size="small"
            />
          )}
          {isMatterSpecific && (
            <>
              <Button
                onClick={() =>
                  navigate(`/embedded/tab?sharingFormId=${extractId(formUri)}`)
                }
                text="Share"
                leftIcon="regularShare"
                variant="primaryDefault"
                disabled={disabled}
              />
              <Button
                onClick={() =>
                  navigate(
                    `/embedded/tab?sharingFormId=${extractId(formUri)}&isInternal=true`,
                  )
                }
                text="Prefill for client"
                leftIcon="regularPencil"
                variant="primaryDefault"
                disabled={disabled}
              />
            </>
          )}
          <Settings
            settingsModal={settingsModal}
            onClose={() => showSettingsModal(false)}
            onSave={saveConfirmationMessage}
            currentConfirmationMessage={confirmationMessage}
            defaultConfirmationMessage={defaultConfirmationMessage}
            placeholderData={placeholderData}
          />
        </>
      )}
    </div>
  );
};

export { Actions };
