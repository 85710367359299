import clsx from "clsx";

import { Button, EditableText } from "@smart/itops-sb-design-system-dom";

import {
  optionContainerClasses,
  optionItemEditClasses,
  optionItemIndicatorClasses,
} from "./class-names";

export type OtherOptionItemProps = {
  type: "choice" | "checkbox";
  isShowingOtherOption?: boolean;
  removeOption: (index?: number) => void;
};

export const OtherOptionItem = ({
  type,
  isShowingOtherOption,
  removeOption,
}: OtherOptionItemProps) =>
  !isShowingOtherOption ? null : (
    <div className={optionContainerClasses} data-testid="other-option-item">
      <div
        className={clsx(
          optionItemIndicatorClasses(type === "choice"),
          "ml-[1.6rem]",
        )}
        data-testid="select-icon-other"
      />
      <div className={clsx(optionItemEditClasses, "text-neutral-600")}>
        <EditableText
          text="Other"
          onChange={() => {}}
          onComplete={async () => {}}
          fontWeight="semibold"
          disabled
        />
      </div>
      <Button
        leftIcon="regularXmark"
        variant="secondarySubtle"
        dataTestId="delete-icon-other"
        onClick={() => removeOption()}
        size="small"
      />
    </div>
  );
