import { Link } from "@smart/itops-sb-design-system-dom";

import { ConditionItem, getItemLabel } from "./utils";

export const ConditionLink = ({
  field,
  setCurrentField,
}: {
  field: ConditionItem | undefined;
  setCurrentField: (field: ConditionItem | null) => void;
}) =>
  field ? (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link text={getItemLabel(field)} onClick={() => setCurrentField(field)} />
  ) : null;
