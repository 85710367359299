import {
  WeekDay,
  calendarWeek,
  endWorkingTimeOfDay,
  startWorkingTimeOfDay,
  toTimeOfDay,
} from "@smart/bridge-intake-components-dom";

export const getDefaultAvailability = () =>
  calendarWeek.map((wd) => ({
    day: wd,
    fromTime: startWorkingTimeOfDay,
    toTime: endWorkingTimeOfDay,
    enabled: wd !== WeekDay.Saturday && wd !== WeekDay.Sunday,
  }));

export const formatDuration = (durationInMinutes: number): string => {
  const { hour, minute } = toTimeOfDay(durationInMinutes);
  const labelParts: string[] = [];
  let day = 0;
  let hourLeft = hour;

  if (hour >= 24) {
    day = Math.floor(hour / 24);
    hourLeft = hour % 24;
  }

  if (day > 0) {
    labelParts.push(`${day} ${day === 1 ? "day" : "days"}`);
  }

  if (hourLeft > 0) {
    labelParts.push(`${hourLeft} ${hourLeft === 1 ? "hr" : "hrs"}`);
  }

  if (minute > 0) {
    labelParts.push(`${minute} ${minute === 1 ? "min" : "mins"}`);
  }

  return labelParts.join(" ");
};
