import {
  DateInputField,
  Select,
  TextFieldInput,
} from "@smart/itops-sb-design-system-dom";
import { jsonParseOrReturn } from "@smart/itops-utils-basic";

import { Condition } from "../conditions";

type ConditionValueProps = {
  condition: Condition;
  onChange: (value: string) => void;
};

const ConditionValue = ({ condition, onChange }: ConditionValueProps) => {
  const field = condition.affectingField;
  const { type } = field;
  const label = "Answer";
  const multiListValue = () => {
    const parsedValue = jsonParseOrReturn(condition.value);
    let valueArray = [];

    if (Array.isArray(parsedValue)) {
      valueArray = parsedValue;
    } else if (parsedValue) {
      valueArray = [parsedValue];
    }
    return (
      <Select
        id={field.uri}
        label={label}
        options={field.options || []}
        value={valueArray}
        onChange={(selected) => {
          onChange(JSON.stringify(selected));
        }}
        className="flex-1"
        dataTestId="condition-value"
        isMultiple
      />
    );
  };

  switch (type) {
    case "number":
      return (
        <TextFieldInput
          label={label}
          name="condition-value"
          value={condition.value}
          onChange={(e) => onChange(e.target.value)}
          type="number"
        />
      );
    case "date":
      return (
        <DateInputField
          label={field.label}
          value={condition.value}
          onChange={onChange}
          onBlur={() => {}}
        />
      );
    case "choice":
    case "yesNo":
      return condition.condition === "equalTo" ? (
        <Select
          id="condition-value"
          label={label}
          options={field.options.map((o) => ({
            label: o.label,
            value: o.value,
          }))}
          value={condition.value}
          onChange={onChange}
          className="flex-1"
        />
      ) : (
        multiListValue()
      );
    case "checkbox":
      return multiListValue();
    default:
      return (
        <TextFieldInput
          label={label}
          name="condition-value"
          onChange={(e) => onChange(e.target.value)}
          value={condition.value}
        />
      );
  }
};

export { ConditionValue, ConditionValueProps };
