import {
  Button,
  Icon,
  SegmentedControl,
  Tooltip,
} from "@smart/itops-sb-design-system-dom";
import { ProgressBar } from "@smart/itops-sb-design-system-dom/src/components/progress-bar";
import { MatterSubmission } from "@smart/manage-gql-client-dom";

import { ActionsMenu } from "../../shared";
import { SubmissionTabOptions } from "../../types";

type SubmissionHeader = {
  formTitle: string;
  loading: {
    form: boolean;
    matterTypes: boolean;
  };
  tab: SubmissionTabOptions;
  onRemind: (matterSubmission: MatterSubmission) => void;
  onCancel: (matterSubmission: MatterSubmission) => void;
  onDelete: (matterSubmission: MatterSubmission) => void;
  onGenerateQRCode: (matterSubmission: MatterSubmission) => void;
  matterSubmission?: MatterSubmission;
  navigateTo: (destination: SubmissionTabOptions | "home") => void;
  setSyncing: React.Dispatch<
    React.SetStateAction<MatterSubmission | undefined>
  >;
  aiFillFromMatterInfo: boolean;
};

export const Header = ({
  formTitle,
  loading,
  tab,
  navigateTo,
  onCancel,
  onDelete,
  onRemind,
  matterSubmission,
  setSyncing,
  onGenerateQRCode,
  aiFillFromMatterInfo,
}: SubmissionHeader) => (
  <div className="relative flex items-center pl-[2.4rem] pr-[4rem] py-4 border-neutral-100 border-solid border-b">
    {(loading.form || matterSubmission?.syncStatus === "loading") &&
      !aiFillFromMatterInfo && (
        <div className="absolute w-full bottom-0 left-0">
          <ProgressBar />
        </div>
      )}
    <div className="flex items-center gap-[2.4rem] flex-1 min-w-0">
      <Button
        leftIcon="regularArrowLeft"
        variant="primarySubtle"
        onClick={() => navigateTo("home")}
      />
      <h1
        className="text-h3 font-semibold font-poppins overflow-hidden whitespace-nowrap text-ellipsis mr-1"
        data-testid="form-title"
        title={formTitle}
      >
        {formTitle}
      </h1>
    </div>
    <div>
      <SegmentedControl
        index={tab === "view" ? 0 : 1}
        items={[
          {
            label: "View",
            disabled: false,
            onClick: () => navigateTo("view"),
          },
          {
            label: "Edit responses",
            disabled: false,
            onClick: () => navigateTo("edit"),
          },
        ]}
      />
    </div>
    <div className="flex justify-end gap-small flex-1">
      {matterSubmission?.syncStatus === "loading" && !aiFillFromMatterInfo && (
        <div className="flex items-center gap-[0.5rem] text-ui">
          <Icon name="solidCircleInfo" />
          Field information is still being loaded
        </div>
      )}
      {tab === "view" && (
        <Tooltip placement="bottom" text="Save responses" offsetBy={10}>
          <Button
            dataTestId="save-responses"
            variant="secondaryDefault"
            leftIcon="lightFloppyDisk"
            onClick={() => setSyncing(matterSubmission)}
            disabled={
              matterSubmission?.syncStatus !== "notSynced" ||
              matterSubmission?.status === "completed" ||
              matterSubmission?.status === "cancelled"
            }
          />
        </Tooltip>
      )}
      <ActionsMenu
        id="header-options"
        onCancel={onCancel}
        onDelete={onDelete}
        onRemind={onRemind}
        onGenerateQRCode={onGenerateQRCode}
        matterSubmission={matterSubmission}
        tooltipText="Actions"
        tooltipPlacement="bottom"
      />
    </div>
  </div>
);
