import { zodResolver } from "@hookform/resolvers/zod";
import { formatDistanceToNow } from "date-fns";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import {
  templateMessagePlaceholders,
  templatePlaceholderDetails,
} from "@smart/bridge-templates-basic";
import {
  familyProQuestionnaireFormUri,
  FamilyProQuestionnaireType,
  questionnaireTypeToFamilyProFormUriMap,
} from "@smart/bridge-types-basic";
import {
  Editor,
  FailureMessage,
  FieldList,
  Link,
  Modal,
  RadioButtons,
  Select,
  TextFieldInput,
} from "@smart/itops-sb-design-system-dom";
import { PlaceholderData } from "@smart/itops-serialisation-basic";
import { Matter, useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import { extractId } from "@smart/itops-types-basic";
import { buildLogProps, isElementOf } from "@smart/itops-utils-basic";
import { MatterSubmission, submissionLink } from "@smart/manage-gql-client-dom";
import {
  communicationMethodLabel,
  CommunicationMethodType,
  communicationMethodValue,
  EventForwarder,
  EventLogger,
  familyProFormTitles,
  RoleContact,
  RoleContacts,
  useFormCollections,
  useToast,
} from "@smart/manage-hooks-dom";

import { setEmail, TabSetEmail } from "../../shared";

const buildReminderSchema = (
  formCollections: { key: string; title: string }[],
) =>
  z.object({
    communicationMethod: z.enum(communicationMethodValue),
    template: z.string().min(1, "Please enter message"),
    form: z
      .object({
        uri: z.string(),
        title: z.string(),
      })
      .optional()
      .nullable()
      .refine((val) => !formCollections.length || val, {
        message: "Please select a form",
      }),
  });

export type SubmissionActionRemindForm = {
  communicationMethod: CommunicationMethodType;
  template: string;
  form:
    | {
        uri: string;
        title: string;
        collectionTitle?: string;
      }
    | undefined
    | null;
};

export type SubmissionActionRemindProps = {
  selected?: MatterSubmission;
  logEvent: EventLogger;
  onConfirm: (
    values: SubmissionActionRemindForm,
    submission: MatterSubmission,
  ) => Promise<void | string>;
  onMessage: (
    values: SubmissionActionRemindForm,
    submission: MatterSubmission,
    placeholderData: PlaceholderData,
  ) => void;
  onClose: () => void;
  matter: Matter;
  roleContacts: RoleContacts;
  matterType: { name: string };
  settingTemplate?: string;
  defaultTemplate: string;
  familyProInviteTemplate?: string | null;
  team: { name: string; phone: string };
};

export const SubmissionActionRemind = ({
  selected,
  logEvent,
  onConfirm,
  onClose,
  onMessage,
  matter,
  matterType,
  roleContacts,
  settingTemplate,
  defaultTemplate,
  familyProInviteTemplate,
  team,
}: SubmissionActionRemindProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<string>();
  const [addOrUpdateEmail, onAddOrUpdateEmail] = useState<RoleContact>();
  const { contacts } = useSmokeballApp();
  const { setToasts } = useToast();
  const isFamilyProQuestionnaireFormUri =
    selected?.formUri === familyProQuestionnaireFormUri;
  const formCollections = useFormCollections({
    availabilities: {
      availableForFamilyPro: isFamilyProQuestionnaireFormUri,
      availableForLead: undefined,
      availableForMatter: undefined,
      availableForMatterFormsOnLeads: undefined,
      availableForMatterSpecificForm: undefined,
    },
    matterTypeId: matter.matterTypeId,
    teamForms: [],
    matterSpecificForms: [],
    globalForms: [],
    teamCategoryForms: [],
    globalCategoryForms: [],
    teamName: team.name,
    matterType: { location: "" },
    isFamilyProFormShared: false,
  });
  const resendingFamilyPro =
    selected?.formUri === familyProQuestionnaireFormUri &&
    selected.status === "completed";

  const getDefaultFormValue = () => {
    if (!selected?.externalSubmissionType) return null;

    const familyProFormUri =
      questionnaireTypeToFamilyProFormUriMap[
        selected.externalSubmissionType as FamilyProQuestionnaireType
      ];
    return {
      uri: familyProFormUri,
      title: familyProFormTitles[familyProFormUri],
    };
  };

  const getDefaultTemplateValue = () => {
    if (!selected?.formUri) return undefined;

    return isFamilyProQuestionnaireFormUri &&
      !resendingFamilyPro &&
      familyProInviteTemplate
      ? familyProInviteTemplate
      : settingTemplate?.trim() || defaultTemplate;
  };

  const form = useForm<SubmissionActionRemindForm>({
    defaultValues: {
      communicationMethod: "communicate",
      template: getDefaultTemplateValue(),
      form: getDefaultFormValue(),
    },
    resolver: zodResolver(buildReminderSchema(formCollections)),
    mode: "onChange",
  });
  const { handleSubmit, control, reset, watch, setValue } = form;

  useEffect(() => {
    if (selected) {
      logEvent({
        type: "start-sending-submission",
        activity: "send-submission",
        formId: extractId(selected.formUri),
        submissionId: extractId(selected.uri),
      });
      const previousMethod = selected.trackers
        ?.map((t) => t?.method)
        .find(isElementOf(communicationMethodValue));
      setValue(
        "communicationMethod",
        previousMethod && previousMethod !== "internalUse"
          ? previousMethod
          : "communicate",
      );
      const defaultFormValue = getDefaultFormValue();
      if (defaultFormValue) setValue("form", defaultFormValue);

      const defaultTemplateValue = getDefaultTemplateValue();
      if (defaultTemplateValue) setValue("template", defaultTemplateValue);
    }
  }, [selected?.uri]);

  const communicationMethod = watch("communicationMethod");
  const formValue = watch("form");

  const existingContact = roleContacts.roleContacts?.find(
    (c) => c.id === selected?.recipient?.contactId,
  );

  /**
   * Attempt to find out recipient's and contactId email if missing based on contactName.
   * The short-term solution for LINK-1136 until Smokeball can call our Generate API with
   * "contactName", "contactId" and "contactEmail".
   */
  if (
    !existingContact &&
    !selected?.recipient?.email &&
    selected?.recipient?.contactName
  ) {
    const foundContactByName = roleContacts.roleContacts?.find(
      (c) =>
        c.person?.firstName === selected.recipient?.contactName ||
        c.company?.name === selected.recipient?.contactName,
    );

    if (foundContactByName) {
      selected.recipient.contactId = foundContactByName.id;
      selected.recipient.email =
        foundContactByName.person?.email ||
        foundContactByName.company?.email ||
        "";
    }
  }

  const getFormTitle = () => {
    let title;

    if (isFamilyProQuestionnaireFormUri && selected?.externalSubmissionType) {
      const familyProFormUri =
        questionnaireTypeToFamilyProFormUriMap[
          selected.externalSubmissionType as FamilyProQuestionnaireType
        ];

      title = familyProFormTitles[familyProFormUri];
    }

    return formValue?.title || title || selected?.formTitle || "";
  };

  const placeholderData = {
    clientName:
      existingContact?.person?.firstName ||
      existingContact?.company?.name ||
      selected?.recipient?.contactName ||
      "",
    matterCategory: matter.isLead ? "lead" : "matter",
    formCreatedAt:
      selected?.createdAt || selected?.updatedAt
        ? formatDistanceToNow(
            new Date(selected?.createdAt || selected?.updatedAt),
            { addSuffix: true },
          )
        : "",
    matterType: matterType.name,
    firmName: team.name,
    firmPhone: team.phone,
    clientEmail:
      existingContact?.person?.email ||
      existingContact?.company?.email ||
      selected?.recipient?.email ||
      "",
    formTitle: getFormTitle(),
  };

  const isInternalUse =
    selected?.status === "draft" &&
    selected.rawSubmission.values.communicationMethod === "internalUse";

  const labels = resendingFamilyPro
    ? {
        subTitle: "Resend",
        sendButton: "Resend",
        action: "resend",
      }
    : {
        subTitle: isInternalUse ? "Send" : "Send reminder",
        sendButton: isInternalUse ? "Send" : "Send reminder",
        action: isInternalUse ? "send" : "send reminder",
      };

  const close = () => {
    reset();
    onClose();
  };
  const onSubmit = handleSubmit(async (values) => {
    setHasError(undefined);
    setIsLoading(true);
    try {
      if (!selected) throw new Error(`Please select a form`);

      const submission = {
        ...selected,
        recipient: {
          ...selected.recipient,
          email: selected.recipient?.email || existingContact?.email,
        },
      } as MatterSubmission;

      const externalSubmissionLink = await onConfirm(values, submission);
      const formLink =
        selected.formUri === familyProQuestionnaireFormUri
          ? selected.externalSubmissionLink || externalSubmissionLink
          : submissionLink(selected.rawSubmission);
      if (!formLink) throw new Error(`Failed to generate link`);

      onMessage(values, submission, { ...placeholderData, formLink });
      close();
    } catch (e) {
      const { logMessage, errorProps } = buildLogProps(e);
      setHasError(`${logMessage} - ${errorProps.message}`);
    }
    setIsLoading(false);
  });

  const emailToUse = selected?.recipient?.email || existingContact?.email || "";
  const getEmailErrorMessage = () => {
    if (!emailToUse) return "Email address required to share form";
    return z.string().email().safeParse(emailToUse).success
      ? undefined
      : "Valid email address required to share form";
  };
  const emailErrorMessage = getEmailErrorMessage();

  return (
    <EventForwarder
      eventTypes={["input", "click", "focus"]}
      eventDataTypeMap={{
        input: "type",
        click: "click",
        focus: "focus",
      }}
      form={selected ? { uri: selected.formUri } : undefined}
      submission={selected ? { uri: selected.uri } : undefined}
      activityType="send-submission"
    >
      <Modal
        header={{
          icon: isInternalUse ? "solidPaperPlaneTop" : "solidBell",
          iconBgColor: "bg-cyan-140",
          text: labels.subTitle,
        }}
        open={!!selected}
        onClose={close}
        footer={{
          buttons: [
            {
              text: "Cancel",
              type: "reset",
              variant: "secondarySubtle",
              onClick: close,
            },
            {
              text:
                communicationMethod === "communicate"
                  ? labels.sendButton
                  : "Open email",
              onClick: onSubmit,
              variant: "primaryDefault",
            },
          ],
        }}
        size="default"
        loading={isLoading}
        closeOptions={{ clickOutside: false }}
      >
        <FormProvider {...form}>
          <FieldList>
            <TextFieldInput
              id="contact"
              label="Contact"
              value={selected?.recipient?.contactName}
              readOnly
              disabled
              mandatory
            />
            <TextFieldInput
              id="email"
              label="Email"
              value={emailToUse}
              error={!!emailErrorMessage}
              message={
                emailErrorMessage ? (
                  <span className="flex gap-medium items-center">
                    <div>{emailErrorMessage}</div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      text={`${
                        emailToUse
                          ? "Update email address for"
                          : "Add email address to"
                      } contact`}
                      onClick={() => onAddOrUpdateEmail(existingContact)}
                    />
                  </span>
                ) : undefined
              }
              readOnly
              disabled
              mandatory
            />
            {!!formCollections.length && (
              <Controller
                control={control}
                name="form"
                render={({ field, fieldState }) => (
                  <Select
                    dataTestId="select-form"
                    id={field.name}
                    label="Form"
                    options={formCollections.map((c) => ({
                      heading: c.title,
                      items: c.options.map((o) => ({
                        label: o.title,
                        value: o,
                      })),
                    }))}
                    value={field.value}
                    onChange={(v) => field.onChange(v)}
                    valueComparer={(o, v) => o?.uri === v?.uri}
                    message={fieldState.error?.message}
                    error={!!fieldState.error}
                    empty="No forms available"
                    mandatory
                  />
                )}
              />
            )}
            <Controller
              control={control}
              name="communicationMethod"
              render={({ field, fieldState }) => (
                <RadioButtons
                  id={field.name}
                  label="Send via"
                  error={!!fieldState.error}
                  message={fieldState.error?.message}
                  options={communicationMethodValue
                    .filter((method) => method !== "internalUse")
                    .map((value) => ({
                      value,
                      label: communicationMethodLabel[value],
                    }))}
                  mandatory
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="template"
              render={({ field, fieldState }) => (
                <div className="flex max-h-[39.65rem] flex-col">
                  <Editor
                    id={field.name}
                    label="Message"
                    error={!!fieldState.error}
                    message={fieldState.error?.message}
                    initialTextValue={getDefaultTemplateValue()}
                    onValueChange={field.onChange}
                    placeholders={{
                      keys: templateMessagePlaceholders,
                      details: templatePlaceholderDetails,
                      data: placeholderData,
                    }}
                    dataTestId="message-editor"
                    mandatory
                  />
                </div>
              )}
            />
            {hasError && (
              <FailureMessage action={labels.action} title={hasError} />
            )}
          </FieldList>
        </FormProvider>
      </Modal>
      <TabSetEmail
        selected={addOrUpdateEmail}
        onClose={() => onAddOrUpdateEmail(undefined)}
        onSetEmail={async ({ roleContact, email }) => {
          try {
            if (!contacts?.update) return;
            await setEmail({ roleContact, email, update: contacts?.update });
            setToasts([
              {
                text: "Email successfully saved to contact",
              },
            ]);
          } catch (e) {
            console.error(e);
          }
        }}
      />
    </EventForwarder>
  );
};
