import { FieldComponentType } from "@smart/bridge-intake-components-dom";
import { IconDetail } from "@smart/itops-icons-dom";
import { IconName } from "@smart/itops-sb-design-system-dom";
import { UIKey } from "@smart/itops-ui-dom";

import { FieldTemplate } from "./types";

export const fieldTemplates: FieldTemplate[] = [
  { type: "mapped" },
  { type: "text" },
  { type: "multilineText" },
  { type: "date" },
  { type: "choice" },
  { type: "checkbox" },
  { type: "currency" },
  { type: "address" },
  { type: "phoneNumber" },
  { type: "email" },
  { type: "file" },
  { type: "info" },
  { type: "number" },
  { type: "group" },
  { type: "appointment" },
  { type: "payment" },
];

export const fieldComponentGroups: {
  name: string;
  components: FieldTemplate[];
}[] = [
  { name: "Structure", components: [{ type: "mapped" }, { type: "group" }] },
  { name: "Choice", components: [{ type: "checkbox" }, { type: "choice" }] },
  {
    name: "Text",
    components: [{ type: "text" }, { type: "multilineText" }, { type: "info" }],
  },
  {
    name: "Numbers",
    components: [{ type: "number" }, { type: "date" }, { type: "currency" }],
  },
  {
    name: "Contact info",
    components: [
      { type: "phoneNumber" },
      { type: "address" },
      { type: "email" },
    ],
  },
  {
    name: "Other",
    components: [
      { type: "payment" },
      { type: "appointment" },
      { type: "file" },
    ],
  },
];

export const fieldComponentIcons: Record<
  FieldComponentType,
  { name: IconName; bgColor: string }
> = {
  mapped: { name: "solidNetworkWired", bgColor: "bg-orange-200" },
  group: { name: "solidObjectGroup", bgColor: "bg-orange-200" },
  checkbox: { name: "solidList", bgColor: "bg-pink-150" },
  choice: { name: "solidListRadio", bgColor: "bg-pink-150" },
  yesNo: { name: "solidListRadio", bgColor: "bg-cyan-140" },
  text: { name: "solidInputText", bgColor: "bg-blue-60" },
  multilineText: { name: "solidMemo", bgColor: "bg-blue-60" },
  info: { name: "solidCircleInfo", bgColor: "bg-blue-60" },
  number: { name: "solidHashtag", bgColor: "bg-green-100" },
  date: { name: "solidCalendar", bgColor: "bg-green-100" },
  currency: { name: "solidDollarSign", bgColor: "bg-green-100" },
  phoneNumber: { name: "solidPhone", bgColor: "bg-orange-50" },
  address: { name: "solidLocationDot", bgColor: "bg-orange-50" },
  email: { name: "solidEnvelope", bgColor: "bg-orange-50" },
  payment: { name: "solidCreditCard", bgColor: "bg-yellow-150" },
  appointment: { name: "solidCalendarClock", bgColor: "bg-yellow-150" },
  file: { name: "solidFileArrowUp", bgColor: "bg-yellow-150" },
};

export const fieldComponentNames: Record<FieldComponentType, string> = {
  mapped: "Mapped fields",
  group: "Group of fields",
  checkbox: "Multiple choice",
  choice: "Single select",
  yesNo: "Yes and No",
  text: "Short text",
  multilineText: "Long text",
  info: "Text info",
  number: "Number",
  date: "Date",
  currency: "Currency",
  phoneNumber: "Phone number",
  address: "Address",
  email: "Email",
  payment: "Smokeball Payments",
  appointment: "Appointment",
  file: "File upload",
};

export const fieldComponentTooltips: Partial<
  Record<FieldComponentType, { title?: string; text: string }>
> = {
  mapped: {
    title: "Mapped fields",
    text: "This allow answers to be mapped back to the matter in Smokeball",
  },
};

export const fieldColor: Record<FieldComponentType, UIKey> = {
  mapped: "green",
  text: "blue",
  multilineText: "blue",
  choice: "blue",
  yesNo: "blue",
  date: "blue",
  currency: "blue",
  address: "blue",
  phoneNumber: "blue",
  email: "blue",
  file: "blue",
  info: "blue",
  number: "blue",
  checkbox: "blue",
  group: "blue",
  appointment: "blue",
  payment: "blue",
};

export const fieldIcon: Record<FieldComponentType, IconDetail> = {
  mapped: { library: "lucide", name: "FolderInput" },
  text: { library: "lucide", name: "PenLine" },
  multilineText: { library: "lucide", name: "WrapText" },
  choice: { library: "lucide", name: "List" },
  yesNo: { library: "lucide", name: "ToggleLeft" },
  date: { library: "lucide", name: "Calendar" },
  currency: { library: "lucide", name: "DollarSign" },
  address: { library: "lucide", name: "MapPin" },
  phoneNumber: { library: "lucide", name: "Phone" },
  email: { library: "lucide", name: "Mail" },
  file: { library: "lucide", name: "FilePlus" },
  info: { library: "lucide", name: "FileText" },
  number: { library: "lucide", name: "Hash" },
  checkbox: { library: "lucide", name: "ListTodo" },
  group: { library: "lucide", name: "Group" },
  appointment: { library: "lucide", name: "CalendarClock" },
  payment: { library: "lucide", name: "Receipt" },
};

export const fieldComponentsDroppableId = "droppable-field-components";
export const fieldSeparatorDroppableIdRegex =
  /droppable-field-separator\|[0-9]+/;
export const sectionItemsDroppableId = "droppable-section-items";
export const sectionNavigationDroppableId = "droppable-section-navigation";
export const groupFieldsDroppableIdPrefix = "droppable-group-fields";
export const groupSeparatorDroppableIdRegex =
  /^smart:group:[0-9a-zA-Z-]+\|\d+$/;
export const sectionItemContainerDroppablePrefix =
  "droppable-section-item-container";
