import clsx from "clsx";
import { useState } from "react";
import { FieldArrayWithId, UseFieldArrayReturn } from "react-hook-form";

import { DraggableItemProps } from "@smart/itops-components-dom";
import { UpdateHookValues } from "@smart/itops-hooks-dom";
import { Button, EditableText, Icon } from "@smart/itops-sb-design-system-dom";

import {
  optionContainerClasses,
  optionItemEditClasses,
  optionItemIndicatorClasses,
} from "./class-names";

export type OptionsProps = {
  allowCustomResponse?: boolean | null | undefined;
  options: { label: string; value: string }[];
};

export type OptionsSharedProps = {
  type: "choice" | "checkbox";
  optionsFields: FieldArrayWithId<
    UpdateHookValues<OptionsProps>,
    "options",
    "id"
  >[];
  removeOption: (index?: number) => Promise<void>;
  updateOption: (index: number, label: string | undefined) => Promise<void>;
};

export type DraggableOptionItemProps = DraggableItemProps<
  UseFieldArrayReturn<OptionsProps, "options">["fields"][number]
> &
  OptionsSharedProps & { newOptionIndex: number | undefined };

export const DraggableOptionItem = ({
  index,
  draggableProvided,
  type,
  removeOption,
  updateOption,
  optionsFields,
  newOptionIndex,
}: DraggableOptionItemProps) => {
  const hasMultipleOptions = optionsFields.length > 1;

  const [editingTitle, setEditingTitle] = useState(
    optionsFields[index]?.label || undefined,
  );

  return (
    <div
      className={optionContainerClasses}
      data-testid={`option-item-${index}`}
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
      draggable={hasMultipleOptions}
    >
      <div className="flex">
        <div
          {...draggableProvided?.dragHandleProps}
          className={clsx(
            "flex justify-center items-center opacity-0 transition duration-200 group-hover:opacity-100",
            !hasMultipleOptions && "invisible pointer-events-none",
          )}
          data-testid="option-drag-handle"
        >
          <Icon name="solidGripDotsVertical" className="w-[0.6rem] px-2" />
        </div>
        <div
          className={optionItemIndicatorClasses(type === "choice")}
          data-testid={`select-icon-${index}`}
        />
      </div>
      <div className={optionItemEditClasses}>
        <EditableText
          text={editingTitle || ""}
          onChange={setEditingTitle}
          placeholder="Option"
          onComplete={() => updateOption(index, editingTitle)}
          editing={newOptionIndex === index}
          fontWeight="semibold"
        />
      </div>
      {hasMultipleOptions && (
        <Button
          leftIcon="regularXmark"
          variant="secondarySubtle"
          dataTestId={`delete-icon-option-${index}`}
          size="small"
          onClick={async () => {
            await removeOption(index);
          }}
        />
      )}
    </div>
  );
};
