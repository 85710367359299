import React, { useEffect, useState } from "react";

import { matterTypeProviderId } from "@smart/bridge-smokeball-basic";
import { Icon, ProgressCircle } from "@smart/itops-sb-design-system-dom";
import { useUser } from "@smart/manage-hooks-dom";

import {
  GqlFieldValues,
  GqlGroupValues,
  GqlMatterLayout,
  GqlMatterType,
  LoadMatterFields,
  MatterField,
} from "../types";
import { buildMappedPathParts } from "../utils";

type MappedFieldPathDisplayProps = {
  layout: NonNullable<GqlFieldValues["layout"]>;
  field: NonNullable<GqlFieldValues["field"]>;
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  loadMatterFields: LoadMatterFields;
  groupLayout?: GqlGroupValues["layout"] | null;
  groupMatterField?: GqlGroupValues["field"] | null;
};

const MappedFieldPathDisplay = ({
  layout,
  field,
  matterLayouts,
  matterTypes,
  loadMatterFields,
  groupLayout,
  groupMatterField,
}: MappedFieldPathDisplayProps) => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [matterFields, setMatterFields] = useState<MatterField[]>([]);
  const layoutToDisplay = groupLayout || layout;

  const matterLayout = matterLayouts?.find((ml) => {
    const isParentIdMatch = layoutToDisplay.parentId
      ? ml.parentId === layoutToDisplay.parentId
      : true;
    return (
      ml.id === layoutToDisplay.id &&
      ml.providerId === layoutToDisplay.providerId &&
      isParentIdMatch
    );
  });
  const displayLayout = matterLayout || layoutToDisplay;

  const loadMatterLayoutFields = async () => {
    if (!matterLayout) return;

    try {
      setLoading(true);
      setMatterFields(
        await loadMatterFields({
          matterLayoutId: matterLayout?.id,
          providerId: matterLayout?.providerId,
          teamUri: user?.teamUri || "",
          matterTypeIds: matterTypes?.map((mt) => mt.id),
        }),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (matterLayout && matterLayout.providerId === matterTypeProviderId) {
      loadMatterLayoutFields().catch(console.error);
    }
  }, [matterLayouts, matterTypes]);

  if (loading) return <ProgressCircle size="xSmall" />;

  return (
    <div className="flex items-center text-ui gap-4 text-neutral-500">
      {buildMappedPathParts({
        layout: displayLayout,
        field,
        groupMatterField,
        matterFields,
      })
        .filter(Boolean)
        .map((part, index, array) => {
          const key = `${part?.replace(" ", "-")}-${index}`;
          return (
            <React.Fragment key={key}>
              <span data-testid={key}>{part}</span>
              {index < array.length - 1 && (
                <div>
                  <Icon
                    className="h-[1.1rem] w-[0.7rem]"
                    name="regularAngleRight"
                    dataTestId={`separator-${index}`}
                  />
                </div>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

export { MappedFieldPathDisplay };
