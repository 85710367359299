import { capitalCase } from "change-case";
import clsx from "clsx";
import { useState } from "react";

import {
  Avatar,
  Button,
  Label,
  Select,
  TextFieldInput,
} from "@smart/itops-sb-design-system-dom";

import { AffectedItem } from "./affected-item";
import { ConditionType } from "./condition-type";
import { ConditionValue } from "./condition-value";
import { getItemType } from "./utils";
import { fieldComponentNames } from "../../../constants";
import {
  GqlFieldValues,
  GqlGroupValues,
  GqlSectionValues,
  ItemType,
  UpdateCondition,
  itemTypeLabels,
} from "../../../types";
import { Condition } from "../conditions";

type ConditionBoxProps = {
  currentField: GqlFieldValues;
  condition: Condition;
  sections: GqlSectionValues[];
  groups: GqlGroupValues[];
  fields: GqlFieldValues[];
  error?: string;
  removeCondition: (id: string) => void;
  updateCondition: UpdateCondition;
};

export const ConditionBox = ({
  currentField,
  sections,
  groups,
  fields,
  condition,
  error,
  updateCondition,
  removeCondition,
}: ConditionBoxProps) => {
  const [itemType, setItemType] = useState<ItemType | undefined>(() =>
    getItemType(condition.affectedItem?.uri),
  );
  const [hideItem, setHideItem] = useState<boolean>(() => !!condition.hide);

  const rowClasses = "flex flex-row items-end gap-4";

  return (
    <Label error={!!error} message={error}>
      <div
        data-testid={`condition-box-${condition.id}`}
        aria-invalid={!!error}
        aria-errormessage={error ? `${condition.id}-error` : undefined}
        className={clsx(
          "flex justify-center items-center bg-white rounded py-[2.4rem] pl-[2rem] relative mb-[1.6rem]",
          error && "border border-red-550 shadow-error",
        )}
      >
        <div className="flex flex-col w-full gap-[2.4rem] pr-8 border-r border-neutral-100">
          <div className={rowClasses}>
            <Avatar size={40} text="If" bgColor="bg-orange-100" />
            <TextFieldInput
              label="Question"
              name="affecting-question"
              dataTestId="affecting-question"
              value={
                condition.affectingField.label ||
                fieldComponentNames[condition.affectingField.type]
              }
              disabled
            />
            <ConditionType
              condition={condition}
              onChange={(conditionType) =>
                updateCondition(condition.id, { condition: conditionType })
              }
              clearConditionValue={() =>
                updateCondition(condition.id, { value: "" })
              }
            />
            <ConditionValue
              condition={condition}
              onChange={(value) => updateCondition(condition.id, { value })}
            />
          </div>
          <div className={rowClasses}>
            <Avatar
              size={40}
              text="Then"
              bgColor="bg-orange-100"
              className="w-[6.2rem]"
            />
            <Select
              dataTestId="hide-show-item"
              label="Result"
              options={["Show", "Hide"].map((item) => ({
                label: item,
                value: item,
              }))}
              value={hideItem ? "Hide" : "Show"}
              onChange={(action: "Show" | "Hide") => {
                const shouldHide = action === "Hide";
                updateCondition(condition.id, { hide: shouldHide });
                setHideItem(shouldHide);
              }}
            />

            <Select
              label="Component"
              options={(["Field", "Section", "Group"] as const).map((item) => ({
                label: capitalCase(itemTypeLabels[item]),
                value: item,
              }))}
              value={itemType}
              onChange={(type) => {
                updateCondition(condition.id, {
                  affectedItem: undefined,
                });
                setItemType(type);
              }}
            />
            <AffectedItem
              label={itemType ? capitalCase(itemTypeLabels[itemType]) : ""}
              itemType={itemType}
              sections={sections}
              groups={groups}
              fields={fields}
              currentField={currentField}
              affectedItem={condition.affectedItem}
              onChange={(affectedItem) => {
                updateCondition(condition.id, { affectedItem });
              }}
            />
          </div>
        </div>
        <div className="flex justify-center items-center min-w-[4.6rem] h-full">
          <Button
            leftIcon="regularTrashCan"
            variant="secondarySubtle"
            onClick={() => removeCondition(condition.id)}
            dataTestId={`remove-condition-${condition.id}`}
          />
        </div>
      </div>
    </Label>
  );
};
