import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";

import {
  appointmentDurations,
  bufferTimes,
  endWorkingTimeOfDay,
  minimumNoticesInMinute,
  startWorkingTimeOfDay,
} from "@smart/bridge-intake-components-dom";
import {
  MeetingType,
  TimeOfDay,
  meetingTypeLabel,
  meetingTypeValue,
} from "@smart/bridge-types-basic";
import {
  FieldList,
  Icon,
  RadioButtons,
  Select,
} from "@smart/itops-sb-design-system-dom";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";
import {
  TimeZone,
  buildTimeZoneList,
  localTimeZoneCode,
  removeKeysRecursively,
} from "@smart/itops-utils-basic";

import { AvailabilityItem } from "./availability-item";
import { GqlFieldValues, OnUpdateField } from "../../types";
import { formatDuration } from "../../utils";

type AppointmentProps = {
  onUpdateField: OnUpdateField;
  field: GqlFieldValues;
};

export const Appointment = ({
  field: fieldValues,
  onUpdateField,
}: AppointmentProps) => {
  const {
    availableStaffIds,
    availability,
    duration,
    bufferTime,
    minimumNotice,
    timezone,
    meetingType,
  } = fieldValues;

  const { staff } = useSmokeballApp();

  const allStaff = staff?.current?.filter((s) => s.enabled) || [];

  const buildFullname = ({
    firstName,
    middleName,
    lastName,
  }: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
  }) => [firstName, middleName, lastName].filter(Boolean).join(" ");
  const timeZoneOptions = buildTimeZoneList().map((tz) => ({
    ...tz,
    label: tz.label.split("/").pop() || "",
  }));
  const timeZoneOptionsByRegion = timeZoneOptions.reduce(
    (
      groupByRegions: { region: string; items: typeof timeZoneOptions }[],
      option,
    ) => {
      const region = groupByRegions.find((r) => r.region === option.region);
      if (!region) {
        groupByRegions.push({
          region: option.region,
          items: [option],
        });
      } else {
        region.items.push(option);
      }
      return groupByRegions;
    },
    [],
  );
  const localTimeZone = timeZoneOptions.find(
    (tz) => tz.tzCode === localTimeZoneCode,
  );
  const timezoneValue = timeZoneOptions.find((tz) => tz.tzCode === timezone);

  const formMethods = useForm<{
    availableStaff: { id: string; fullName: string }[];
    duration: number;
    availability?:
      | {
          day: number;
          fromTime: TimeOfDay;
          toTime: TimeOfDay;
          enabled: boolean;
        }[]
      | null;
    bufferTime?: number | null;
    minimumNotice?: number | null;
    meetingType: MeetingType;
    timezone?: TimeZone;
  }>({
    defaultValues: {
      availableStaff: allStaff
        .filter((s) => availableStaffIds?.includes(s.id))
        .map((s) => ({
          id: s.id,
          fullName: buildFullname(s),
        })),
      duration: duration || 15,
      availability: availability?.map((a) =>
        removeKeysRecursively(a, ["__typename"]),
      ),
      bufferTime,
      minimumNotice,
      meetingType: meetingType || "inPerson",
      timezone: timezoneValue || localTimeZone,
    },
  });

  const { control, handleSubmit, watch } = formMethods;

  const submit = handleSubmit(async (values) => {
    await onUpdateField({
      field: fieldValues,
      updated: {
        availableStaffIds: values.availableStaff.map((s) => s.id),
        duration: values.duration,
        availability: values.availability || undefined,
        bufferTime: values.bufferTime || undefined,
        minimumNotice: values.minimumNotice || undefined,
        meetingType: values.meetingType,
        timezone: values.timezone?.tzCode || localTimeZoneCode,
      },
    });
  });

  const availabilityOptions = useFieldArray({ control, name: "availability" });
  const selectedMeetingType = watch("meetingType");

  return (
    <FormProvider {...formMethods}>
      <FieldList className="text-ui mt-2">
        <Controller
          control={control}
          name="availableStaff"
          render={({ field, fieldState }) => (
            <div data-testid="appointment-available-staff">
              <Select
                id={field.name}
                label="Select team members"
                title="Available team members"
                placeholder="Select team members"
                options={allStaff.map((s) => ({
                  value: s,
                  label: buildFullname(s),
                }))}
                empty="No team members available"
                error={!!fieldState.error}
                message={fieldState.error?.message}
                value={field.value}
                valueComparer={(o, v) => o?.id === v?.id}
                onChange={async (v) => {
                  field.onChange(v);
                  await submit();
                }}
                isMultiple
              />
            </div>
          )}
        />
        <div className="flex items-start justify-between gap-4">
          <Controller
            control={control}
            name="duration"
            render={({ field, fieldState }) => (
              <div data-testid="appointment-duration">
                <Select
                  className="flex-1"
                  id={field.name}
                  label="Duration"
                  title="Duration"
                  options={appointmentDurations.map((d) => ({
                    value: d,
                    label: `${d} minutes`,
                  }))}
                  empty="No options"
                  error={!!fieldState.error}
                  message={fieldState.error?.message}
                  value={field.value}
                  onChange={async (v) => {
                    field.onChange(v);
                    await submit();
                  }}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="bufferTime"
            render={({ field, fieldState }) => (
              <div data-testid="appointment-buffer-time">
                <Select
                  className="flex-1"
                  id={field.name}
                  label="Buffer"
                  title="Buffer time"
                  options={bufferTimes.map((b) => ({
                    value: b,
                    label: `${b} minutes`,
                  }))}
                  empty="No options"
                  error={!!fieldState.error}
                  message={fieldState.error?.message}
                  value={field.value}
                  onChange={async (v) => {
                    field.onChange(v);
                    await submit();
                  }}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="minimumNotice"
            render={({ field, fieldState }) => (
              <div data-testid="appointment-minimum-notice">
                <Select
                  className="flex-1"
                  id={field.name}
                  label="Minimum notice"
                  options={minimumNoticesInMinute.map((m) => ({
                    value: m,
                    label: formatDuration(m),
                  }))}
                  empty="No options"
                  error={!!fieldState.error}
                  message={fieldState.error?.message}
                  value={field.value}
                  onChange={async (v) => {
                    field.onChange(v);
                    await submit();
                  }}
                />
              </div>
            )}
          />
        </div>
        <div id="availability" data-testid="appointment-availability">
          <div className="text-ui font-semibold mb-2">Available hours</div>
          {availabilityOptions.fields.map((o, index) => (
            <AvailabilityItem
              key={o.id}
              dailyAvailability={o}
              onChange={async (item) => {
                availabilityOptions.update(index, {
                  ...item,
                  fromTime: item.fromTime || startWorkingTimeOfDay,
                  toTime: item.toTime || endWorkingTimeOfDay,
                });
                await submit();
              }}
            />
          ))}
        </div>
        <div>
          <div className="flex gap-4">
            <Controller
              control={control}
              name="meetingType"
              render={({ field, fieldState }) => (
                <div
                  className="flex-1 pl-1"
                  data-testid="appointment-meeting-type"
                >
                  <RadioButtons
                    id={field.name}
                    label="Type"
                    error={!!fieldState.error}
                    message={fieldState.error?.message}
                    options={meetingTypeValue.map((t) => ({
                      value: t,
                      label: meetingTypeLabel[t],
                    }))}
                    onChange={async (v) => {
                      field.onChange(v);
                      await submit();
                    }}
                    value={field.value}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="timezone"
              render={({ field, fieldState }) => (
                <div
                  data-testid="appointment-time-zone"
                  className="flex-1 ml-6"
                >
                  <Select
                    id={field.name}
                    label="Timezone"
                    options={timeZoneOptionsByRegion.map((byRegion) => ({
                      heading: byRegion.region,
                      items: byRegion.items.map((tz) => ({
                        value: tz,
                        label: tz.label,
                      })),
                    }))}
                    empty="No options"
                    error={!!fieldState.error}
                    message={fieldState.error?.message}
                    valueComparer={(o, v) => o.tzCode === v.tzCode}
                    value={field.value}
                    onChange={async (v) => {
                      field.onChange(v);
                      await submit();
                    }}
                  />
                </div>
              )}
            />
          </div>
          {selectedMeetingType === "telephoneConsult" && (
            <div className="flex items-center gap-1 mt-2 ml-3 text-neutral-600">
              <Icon className="w-4 h-4 mr-2" name="regularCircleExclamation" />
              <div className="text-caption font-normal">
                A mandatory phone number field should be added to the form
              </div>
            </div>
          )}
        </div>
      </FieldList>
    </FormProvider>
  );
};
