import { useState } from "react";

import { FieldType } from "@smart/bridge-types-basic";
import { Button, Modal, Switch } from "@smart/itops-sb-design-system-dom";

type ActionsProps = {
  type: FieldType;
  descriptionVisible: boolean;
  mandatory: boolean;
  onToggleDescription: (visible: boolean) => void;
  onToggleMandatory: (mandatory: boolean) => void;
  onDelete: () => void;
};

export const Actions = ({
  type,
  descriptionVisible,
  mandatory,
  onToggleDescription,
  onToggleMandatory,
  onDelete,
}: ActionsProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <div className="flex gap-small h-full justify-end items-start flex-wrap w-[26rem] pr-[4px]">
      {type !== "info" && (
        <div className="h-full flex flex-wrap items-start pt-[0.8rem]">
          <Switch
            text="Required"
            toggled={mandatory}
            onClick={() => onToggleMandatory(!mandatory)}
          />
        </div>
      )}
      <Button
        variant="secondarySubtle"
        leftIcon="regularText"
        onClick={() => onToggleDescription(!descriptionVisible)}
        size="small"
        isPressed={descriptionVisible}
        dataTestId="toggle-description"
      />
      <Button
        variant="secondarySubtle"
        leftIcon="regularTrashCan"
        onClick={() => setIsDeleteModalOpen(true)}
        size="small"
        dataTestId="delete-field"
      />
      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        header={{
          icon: "solidTrashCan",
          text: "Delete item",
        }}
        footer={{
          buttons: [
            {
              variant: "secondarySubtle",
              text: "Cancel",
              onClick: () => setIsDeleteModalOpen(false),
            },
            {
              variant: "primaryDefault",
              text: "Yes, delete",
              onClick: () => {
                setIsDeleteModalOpen(false);
                onDelete();
              },
            },
          ],
        }}
      >
        Are you sure you want to delete the item?
      </Modal>
    </div>
  );
};
