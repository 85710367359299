import { LinkConditionType } from "@smart/bridge-types-basic";
import { Select } from "@smart/itops-sb-design-system-dom";

import { ConditionItem, isConditionField } from "./utils";
import { GqlFieldValues } from "../../../types";
import { Condition } from "../conditions";

const availableConditions: Record<
  "text" | "number" | "date" | "choice" | "checkbox" | "yesNo",
  { condition: LinkConditionType; displayText: string }[]
> = {
  text: [
    { condition: "equalTo", displayText: "is" },
    { condition: "contain", displayText: "includes" },
  ],
  number: [
    { condition: "equalTo", displayText: "is" },
    { condition: "notEqualTo", displayText: "is not" },
    { condition: "greaterThan", displayText: "is greater than" },
    { condition: "lessThan", displayText: "is less than" },
  ],
  date: [
    { condition: "equalTo", displayText: "is" },
    { condition: "notEqualTo", displayText: "is not" },
    { condition: "greaterThan", displayText: "is after" },
    { condition: "lessThan", displayText: "is before" },
  ],
  choice: [
    { condition: "equalTo", displayText: "is" },
    { condition: "in", displayText: "is any of" },
  ],
  checkbox: [
    { condition: "equalTo", displayText: "is" },
    { condition: "in", displayText: "includes any of" },
    { condition: "contain", displayText: "includes all of" },
  ],
  yesNo: [{ condition: "equalTo", displayText: "is" }],
};

export const getOptions = (type: GqlFieldValues["type"]) => {
  switch (type) {
    case "text":
    case "number":
    case "date":
    case "choice":
    case "checkbox":
    case "yesNo":
      return availableConditions[type];
    default:
      return [];
  }
};

export const hasOptions = (item: ConditionItem | null) => {
  if (!item || !isConditionField(item)) return false;

  return !!getOptions(item.type).length;
};

type ConditionTypeProps = {
  condition: Condition;
  onChange: (conditionType: LinkConditionType) => void;
  clearConditionValue: () => void;
};

const ConditionType = ({
  condition,
  onChange,
  clearConditionValue,
}: ConditionTypeProps) => {
  const options = getOptions(condition.affectingField.type);

  return (
    <Select
      dataTestId="condition-type"
      label="Choice"
      options={options.map((o) => ({
        label: o.displayText,
        value: o.condition,
      }))}
      value={condition.condition}
      onChange={(selected) => {
        if (
          condition.affectingField.type === "choice" &&
          condition.condition !== selected.condition
        ) {
          clearConditionValue();
        }
        onChange(selected);
      }}
    />
  );
};

export { ConditionType, ConditionTypeProps };
