import {
  convertToHtml,
  convertToText,
  PlaceholderData,
} from "@smart/itops-serialisation-basic";
import { useSmokeballApp } from "@smart/itops-smokeball-app-dom";

import { useToast } from "./toast";

export const communicationMethodValue = [
  "communicate",
  "email",
  "internalUse",
] as const;

export type CommunicationMethodType = (typeof communicationMethodValue)[number];

export const communicationMethodLabel: Record<CommunicationMethodType, string> =
  {
    email: "Email",
    communicate: "Client Portal",
    internalUse: "Internal use",
  };

export const useSendMessage = () => {
  const { communicate, correspondence, host, hostType } = useSmokeballApp();
  const { setToasts } = useToast();

  return {
    sendMessage: ({
      id,
      email,
      communicationMethod,
      subject,
      template,
      placeholderData,
      actionText,
    }: {
      id: string;
      email: string;
      communicationMethod: "email" | "communicate";
      subject: string;
      template: string;
      placeholderData: PlaceholderData;
      actionText?: string;
    }) => {
      switch (communicationMethod) {
        case "email": {
          correspondence?.email({
            subject,
            to: [{ emailAddress: email }],
            bodyHtml: convertToHtml({ template, data: placeholderData }),
            bodyText: convertToText({
              template,
              prettyDisplayText: true,
              data: placeholderData,
            }),
          });
          break;
        }
        case "communicate": {
          const messageRequest = {
            message: convertToText({ template, data: placeholderData }),
            contactIds: [id],
          };
          if (
            hostType === "windows" &&
            host?.isEarlierThanVersion({ major: 8, minor: 10 })
          ) {
            communicate?.createMessage(messageRequest);
          } else {
            communicate?.sendMessage(messageRequest);
          }
          setToasts([
            {
              text: `${actionText || "Form successfully shared"} via ${
                communicationMethodLabel.communicate
              }`,
            },
          ]);
          break;
        }
        default:
          break;
      }
    },
  };
};
