import { useState } from "react";

import {
  defaultTemplate,
  templateResponsePlaceholders,
  templatePlaceholderDetails,
  TemplateResponseData,
  appendDownloadFormLink,
} from "@smart/bridge-templates-basic";
import { Editor, Modal } from "@smart/itops-sb-design-system-dom";

type SettingsProps = {
  onSave: (response: string | undefined) => Promise<void>;
  onClose: () => void;
  settingsModal: boolean;
  currentConfirmationMessage: string;
  defaultConfirmationMessage?: string | null;
  placeholderData: Partial<TemplateResponseData>;
};

export const Settings = ({
  onSave,
  onClose,
  settingsModal,
  currentConfirmationMessage,
  defaultConfirmationMessage,
  placeholderData,
}: SettingsProps) => {
  const [editedMessage, setEditedMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    await onSave(editedMessage);
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      loading={loading}
      header={{
        icon: "solidGear",
        iconBgColor: "bg-cyan-140",
        text: "Form settings",
      }}
      footer={{
        buttons: [
          {
            text: "Cancel",
            variant: "secondarySubtle",
            onClick: onClose,
          },
          { text: "Save", variant: "primaryDefault", onClick: save },
        ],
      }}
      open={settingsModal}
      onClose={onClose}
    >
      <h3 className="text-ui font-semibold">Confirmation Message</h3>
      <p className="text-ui">
        This message will be shown after the form has been submitted:
      </p>
      <div className="flex max-h-[32.15rem] flex-col">
        <Editor
          id="message"
          label="Message"
          dataTestId="message-editor"
          initialTextValue={appendDownloadFormLink(
            currentConfirmationMessage ||
              defaultConfirmationMessage ||
              defaultTemplate.response,
          )}
          onValueChange={setEditedMessage}
          placeholders={{
            keys: templateResponsePlaceholders,
            details: templatePlaceholderDetails,
            data: placeholderData,
          }}
          mandatory
        />
      </div>
    </Modal>
  );
};
