import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import clsx from "clsx";
import { useEffect, useState } from "react";

import { FieldComponentType } from "@smart/bridge-intake-components-dom";
import { Card, EditableText, Icon } from "@smart/itops-sb-design-system-dom";

import { FieldTypeIndicator } from "./field-type-indicator";
import { fieldComponentNames } from "../../constants";
import { ConditionsButton } from "../../edit-view/condition/conditions-button";
import {
  ConditionActions,
  FieldTemplate,
  GqlFieldValues,
  GqlGroupValues,
  GqlMatterLayout,
  GqlMatterType,
  LoadMatterFields,
  OnDeleteField,
  OnFieldTypeChange,
  OnUpdateField,
} from "../../types";
import { Actions } from "../actions";
import { FieldBox } from "../field-box";

type FieldCardProps = {
  loading?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  isShrinking?: boolean;
  isDragging?: boolean;
  editing?: boolean;
  onUpdateField: OnUpdateField;
  onDeleteField: OnDeleteField;
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  loadMatterFields: LoadMatterFields;
  field: GqlFieldValues;
  conditionActions: ConditionActions;
  groupLayout?: GqlGroupValues["layout"] | null;
  groupMatterField?: GqlGroupValues["field"] | null;
  onFieldTypeChange: OnFieldTypeChange;
  excludingFieldTemplates: FieldTemplate[];
};

export const FieldCard = ({
  loading,
  isShrinking,
  isDragging,
  onUpdateField,
  onDeleteField,
  dragHandleProps,
  editing,
  matterLayouts,
  matterTypes,
  loadMatterFields,
  field,
  conditionActions,
  groupLayout,
  groupMatterField,
  onFieldTypeChange,
  excludingFieldTemplates,
}: FieldCardProps) => {
  const {
    type,
    label: question,
    description,
    mandatory,
    field: matterField,
    layout,
  } = field;

  const [editedQuestion, setEditedQuestion] = useState(
    question || fieldComponentNames[type],
  );
  const [editedDescription, setEditedDescription] = useState(description);
  const [descriptionVisible, setDescriptionVisible] = useState(!!description);
  const [isEditing, setIsEditing] = useState(editing);

  const handleEditComplete = () =>
    onUpdateField({
      field,
      updated: {
        label: editedQuestion,
        description: editedDescription ?? undefined,
      },
    });

  useEffect(() => {
    setIsEditing(editing);
  }, [editing]);

  const onChangeFieldType = async (newFieldType: FieldComponentType) => {
    await onFieldTypeChange({ field, newFieldType });
  };

  return (
    <div className="flex flex-col justify-center grow w-full content-center flex-wrap">
      <Card
        className={clsx(
          "border-none p-[0.4rem] pr-[3.2rem] shadow-none",
          isDragging && "border-solid border-2 border-cyan-240",
        )}
        current={isEditing}
        flow="row"
        loading={loading}
      >
        <div className="flex gap-small w-full">
          <div
            className="flex items-center justify-center px-[1.05rem] rounded-l hover:bg-black/5"
            {...dragHandleProps}
          >
            <Icon
              name="solidGripDotsVertical"
              className="w-[1.1rem] h-[2.2rem]"
            />
          </div>
          <div className="flex flex-col justify-center w-full overflow-hidden pb-[1.2rem]">
            <div className="flex items-center w-full mt-[0.8rem] mb-[0.8rem]">
              <div className="flex flex-col items-start justify-center relative flex-1 overflow-hidden">
                <EditableText
                  text={editedQuestion}
                  onChange={setEditedQuestion}
                  onComplete={handleEditComplete}
                  isMandatory={mandatory}
                  editing={isEditing}
                  onEditing={setIsEditing}
                  fontWeight="semibold"
                  isEditor
                />
                {descriptionVisible && (
                  <EditableText
                    text={editedDescription}
                    onChange={setEditedDescription}
                    onComplete={handleEditComplete}
                    placeholder="Description"
                    isEditor
                  />
                )}
              </div>
              <Actions
                type={type}
                descriptionVisible={descriptionVisible}
                mandatory={mandatory}
                onToggleDescription={async (checked) => {
                  setDescriptionVisible(checked);
                  if (!checked) {
                    setEditedDescription("");
                    await onUpdateField({
                      field,
                      updated: { description: "" },
                    });
                  }
                }}
                onToggleMandatory={async (isMandatory) => {
                  await onUpdateField({
                    field,
                    updated: { mandatory: isMandatory },
                  });
                }}
                onDelete={() => onDeleteField(field)}
              />
            </div>
            {!isShrinking && (
              <FieldBox field={field} onUpdateField={onUpdateField} />
            )}
            <div
              className="flex items-center mt-[0.8rem] justify-between gap-4"
              data-testid="field-card-footer"
            >
              <FieldTypeIndicator
                fieldType={type}
                matterField={matterField}
                layout={layout}
                matterLayouts={matterLayouts}
                matterTypes={matterTypes}
                loadMatterFields={loadMatterFields}
                groupMatterField={groupMatterField}
                groupLayout={groupLayout}
                onChangeFieldType={onChangeFieldType}
                excludingFieldTemplates={excludingFieldTemplates}
              />
              {type !== "info" && (
                <ConditionsButton
                  numberOfConditions={conditionActions.getNumberOfConditions(
                    field.uri,
                  )}
                  onClick={() => conditionActions.setEditingCondition(field)}
                  size="small"
                />
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
