import { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import {
  WeekDay,
  buildTimeOfDayRange,
  calendarWeekLabel,
  formatTimeOfDay,
  timeOfDayInMinutes,
} from "@smart/bridge-intake-components-dom";
import { TimeOfDay } from "@smart/bridge-types-basic";
import { Checkbox, Icon, Select } from "@smart/itops-sb-design-system-dom";

export type AvailableDayProps = {
  id: string;
  day: number;
  fromTime: TimeOfDay;
  toTime: TimeOfDay;
  enabled: boolean;
};

export type AvailabilityItemProps = {
  dailyAvailability: AvailableDayProps;
  onChange: ({
    day,
    fromTime,
    toTime,
    enabled,
  }: {
    day: number;
    fromTime: TimeOfDay | null;
    toTime: TimeOfDay | null;
    enabled: boolean;
  }) => void;
};

export const AvailabilityItem = ({
  dailyAvailability: { id, day, fromTime, toTime, enabled },
  onChange,
}: AvailabilityItemProps) => {
  const formMethods = useForm<{
    day: number;
    fromTime: TimeOfDay | null;
    toTime: TimeOfDay | null;
    enabled: boolean;
  }>({
    defaultValues: {
      day,
      fromTime,
      toTime,
      enabled,
    },
  });
  const timeRange = buildTimeOfDayRange();
  const fromTimeRange = timeRange.slice(0, timeRange.length - 1);
  const toTimeRange = timeRange.slice(1);

  const { control, handleSubmit, setValue, watch } = formMethods;
  const availabilityEnabled = watch("enabled");
  const fromTimeValue = watch("fromTime");
  const toTimeValue = watch("toTime");

  const onSubmit = handleSubmit((values) => onChange(values));

  useEffect(() => {
    if (!availabilityEnabled) {
      setValue("fromTime", null);
      setValue("toTime", null);
    } else {
      setValue("fromTime", fromTime);
      setValue("toTime", toTime);
    }
  }, [availabilityEnabled]);

  useEffect(() => {
    if (!fromTimeValue || !toTimeValue) return;

    if (timeOfDayInMinutes(fromTimeValue) >= timeOfDayInMinutes(toTimeValue)) {
      const startTimeIndex = timeRange.findIndex(
        (t) =>
          t.hour === fromTimeValue.hour && t.minute === fromTimeValue.minute,
      );
      setValue(
        "toTime",
        timeRange[Math.min(timeRange.length - 1, startTimeIndex + 1)],
      );
      onSubmit().catch(console.error);
    }
  }, [fromTimeValue]);

  useEffect(() => {
    if (!fromTimeValue || !toTimeValue) return;

    if (timeOfDayInMinutes(toTimeValue) <= timeOfDayInMinutes(fromTimeValue)) {
      const endTimeIndex = timeRange.findIndex(
        (t) => t.hour === toTimeValue.hour && t.minute === toTimeValue.minute,
      );
      setValue("fromTime", timeRange[Math.max(0, endTimeIndex - 1)]);
      onSubmit().catch(console.error);
    }
  }, [toTimeValue]);

  return (
    <FormProvider {...formMethods}>
      <div
        data-testid={`appointment-availability-${day}`}
        className="flex gap-4 mb-4"
      >
        <Controller
          control={control}
          name="enabled"
          render={({ field }) => (
            <div
              id={field.name}
              className="flex items-center flex-1 bg-white border-neutral-200 border rounded px-4"
            >
              <Checkbox
                value={id}
                label={calendarWeekLabel[day as WeekDay]}
                checked={field.value}
                onChange={async (checked) => {
                  field.onChange(checked);
                  await onSubmit();
                }}
              />
            </div>
          )}
        />
        <div className="flex flex-1 gap-2 items-center">
          <Controller
            control={control}
            name="fromTime"
            render={({ field, fieldState }) => (
              <div id={field.name} className="flex-1">
                <Select
                  id={`${field.name}-${id}`}
                  title="From time"
                  options={fromTimeRange.map((range) => ({
                    value: range,
                    label: formatTimeOfDay(range),
                  }))}
                  valueComparer={(o, v) =>
                    o && v && timeOfDayInMinutes(o) === timeOfDayInMinutes(v)
                  }
                  value={field.value}
                  error={!!fieldState.error}
                  message={fieldState.error?.message}
                  onChange={async (v) => {
                    field.onChange(v);
                    await onSubmit();
                  }}
                  disabled={!availabilityEnabled}
                  dataTestId={`from-time-${day}`}
                />
              </div>
            )}
          />
          <Icon name="regularMinus" />
          <Controller
            control={control}
            name="toTime"
            render={({ field, fieldState }) => (
              <div id={field.name} className="flex-1">
                <Select
                  id={`${field.name}-${id}`}
                  title="To time"
                  options={toTimeRange.map((range) => ({
                    value: range,
                    label: formatTimeOfDay(range),
                  }))}
                  valueComparer={(o, v) =>
                    o && v && timeOfDayInMinutes(o) === timeOfDayInMinutes(v)
                  }
                  value={field.value}
                  error={!!fieldState.error}
                  message={fieldState.error?.message}
                  onChange={async (v) => {
                    field.onChange(v);
                    await onSubmit();
                  }}
                  disabled={!availabilityEnabled}
                  dataTestId={`to-time-${day}`}
                />
              </div>
            )}
          />
        </div>
      </div>
    </FormProvider>
  );
};
