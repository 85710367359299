import { Alert } from "@smart/itops-sb-design-system-dom";

import { GqlField, GqlGroup } from "../../types";
import { ItemToUpdate } from "../hooks";
import { GqlFieldValues } from "../types";
import { buildMappedPathParts } from "../utils";

const RemapStatuses = ({
  itemsToUpdate,
  groups,
  fields,
  type,
}: {
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
  itemsToUpdate: ItemToUpdate[];
  type: "group" | "field";
}) => (
  <>
    {itemsToUpdate.map((item) => {
      const isGroup = type === "group";
      const itemValues = (isGroup ? groups : fields)?.find(
        (i) => i.uri === item.uri,
      )?.values;
      const group = isGroup
        ? undefined
        : groups?.find(
            (g) => g.uri === (itemValues as GqlFieldValues).groupUri,
          );

      if (!itemValues) return null;

      return (
        <li key={item.uri}>
          {itemValues.label || item.uri} (
          {(isGroup
            ? buildMappedPathParts({
                layout: itemValues.layout!,
                field: itemValues.field!,
                matterFields: [],
              })
            : buildMappedPathParts({
                layout: group?.values.layout || itemValues.layout!,
                field: itemValues.field!,
                groupMatterField: group?.values.field,
                matterFields: [],
              })
          )
            .filter(Boolean)
            .join(" > ")}
          ){isGroup && " [group]"}
        </li>
      );
    })}
  </>
);

type RemappingFieldListProps = {
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
  groupsToUpdate: ItemToUpdate[];
  fieldsToUpdate: ItemToUpdate[];
};

export const RemappingFieldList = ({
  groups,
  fields,
  groupsToUpdate,
  fieldsToUpdate,
}: RemappingFieldListProps) => {
  const groupsToRemoveMapping = groupsToUpdate.filter(
    (g) => !g.layout && !g.field,
  );
  const fieldsToRemoveMapping = fieldsToUpdate.filter(
    (f) => !f.layout && !f.field,
  );
  const isRemoving =
    groupsToRemoveMapping.length || fieldsToRemoveMapping.length;

  return isRemoving ? (
    <div className="mt-8">
      <Alert
        heading="Fields do not match the new matter type and will be unmapped"
        content={
          <div className="pl-6 disc">
            {!!groupsToRemoveMapping.length && (
              <RemapStatuses
                groups={groups}
                fields={fields}
                itemsToUpdate={groupsToRemoveMapping}
                type="group"
              />
            )}
            {!!fieldsToRemoveMapping.length && (
              <RemapStatuses
                groups={groups}
                fields={fields}
                itemsToUpdate={fieldsToRemoveMapping}
                type="field"
              />
            )}
            <p className="mt-8">
              To map them, update your fields to match the new matter type.
            </p>
          </div>
        }
        palette="warning"
        variant="section"
      />
    </div>
  ) : null;
};
