import { DraggableItemProps } from "@smart/itops-components-dom";
import { ProgressBar } from "@smart/itops-sb-design-system-dom";
import { removeKeys } from "@smart/itops-utils-basic";
import { useDeleteGroup, useUpdateGroup } from "@smart/manage-gql-client-dom";

import { GroupCard } from "./group-part";
import {
  ConditionActions,
  FieldTemplate,
  GqlFieldValues,
  GqlGroupValues,
  GqlMatterLayout,
  GqlMatterType,
  LoadMatterFields,
  OnDeleteField,
  OnFieldTypeChange,
  OnUpdateField,
  QuestionEditMode,
} from "../../types";
import { Separator } from "../separator";

type GroupProps = DraggableItemProps<GqlGroupValues> & {
  mode?: QuestionEditMode;
  item: GqlGroupValues;
  fields: GqlFieldValues[];
  updateGroup: ReturnType<typeof useUpdateGroup>[0];
  deleteGroup: ReturnType<typeof useDeleteGroup>[0];
  isDragging?: boolean;
  isEditing?: boolean;
  loading?: boolean;
  onUpdateField: OnUpdateField;
  onDeleteField: OnDeleteField;
  matterLayouts?: GqlMatterLayout[];
  matterTypes?: GqlMatterType[];
  loadMatterFields: LoadMatterFields;
  updatingItems: Record<string, boolean | undefined>;
  editingItemUri?: string;
  conditionActions: ConditionActions;
  onFieldTypeChange: OnFieldTypeChange;
  isDropAnimating: boolean;
  isValidSection: boolean;
  excludingFieldTemplates: FieldTemplate[];
};

export const Group = ({
  item: group,
  fields,
  index,
  mode,
  loading,
  isEditing,
  isDragging,
  matterTypes,
  deleteGroup,
  updateGroup,
  onUpdateField,
  onDeleteField,
  matterLayouts,
  editingItemUri,
  updatingItems,
  loadMatterFields,
  draggableProvided,
  conditionActions,
  onFieldTypeChange,
  isDropAnimating,
  isValidSection,
  excludingFieldTemplates,
}: GroupProps) => {
  const onDeleteGroup = async () => {
    await deleteGroup({
      variables: {
        sectionUri: group.sectionUri,
        uri: group.uri,
        formUri: group.formUri,
      },
    });
  };

  const onUpdateGroup = async ({
    label,
    description,
    repeatable,
    repeatPrompt,
  }: {
    label?: string;
    description?: string;
    repeatable?: boolean;
    repeatPrompt?: string;
  }) => {
    await updateGroup({
      variables: {
        uri: group.uri,
        formUri: group.formUri,
        sectionUri: group.sectionUri,
        order: group.order,
        fields: {
          description: description ?? (group.description || undefined),
          allowedRepeatable: !!group.allowedRepeatable,
          field: group.field
            ? {
                ...removeKeys(group.field, [
                  "__typename",
                  "displayName",
                  "details",
                ]),
              }
            : undefined,
          hint: group.hint,
          label: label ?? group.label,
          layout: group.layout
            ? {
                ...removeKeys(group.layout, ["__typename", "displayName"]),
                parentId: group.layout.parentId || undefined,
                parentName: group.layout.parentName || undefined,
                parentProviderId: group.layout.parentProviderId || undefined,
              }
            : undefined,
          links: group.links
            ? group.links.map((link) => ({
                ...removeKeys(link, ["__typename"]),
                fieldUri: link.fieldUri,
              }))
            : undefined,
          maxRepeat: group.maxRepeat || undefined,
          minRepeat: group.minRepeat || undefined,
          repeatPrompt: repeatPrompt ?? (group.repeatPrompt || undefined),
          repeatable:
            repeatable !== undefined ? repeatable : !!group.repeatable,
          templateType: group.templateType || undefined,
          type: group.type,
        },
      },
    });
  };

  return (
    <div
      className="w-full pl-2 pr-6"
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
      style={
        isValidSection && isDropAnimating
          ? {
              ...draggableProvided?.draggableProps.style,
              transitionDuration: "0.000001ms",
            }
          : draggableProvided?.draggableProps.style
      }
    >
      <GroupCard
        group={group}
        mode={mode}
        loading={loading}
        editing={isEditing}
        isDragging={isDragging}
        matterTypes={matterTypes}
        groupFields={fields}
        onUpdateField={onUpdateField}
        onDeleteField={onDeleteField}
        matterLayouts={matterLayouts}
        onDeleteGroup={onDeleteGroup}
        onUpdateGroup={onUpdateGroup}
        editingItemUri={editingItemUri}
        loadMatterFields={loadMatterFields}
        updatingItems={updatingItems}
        dragHandleProps={draggableProvided?.dragHandleProps}
        conditionActions={conditionActions}
        onFieldTypeChange={onFieldTypeChange}
        isDropAnimating={isDropAnimating}
        isValidSection={isValidSection}
        excludingFieldTemplates={excludingFieldTemplates}
      />
      {loading && <ProgressBar className="h-[0.3rem]" />}
      <Separator index={index + 1} isFieldReordering={mode === "reordering"} />
    </div>
  );
};
