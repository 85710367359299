import { clsx } from "clsx";

export const groupStyles = {
  header:
    "flex justify-between items-center pl-20 pt-[1rem] pb-[0.5rem] w-full",
  title: "flex items-center gap-1 flex-1 overflow-auto max-h-[9rem]",
  content: "flex pt-2",
  dragHandle: "flex min-h-full w-fit px-6 items-center justify-center",
  main: "flex flex-col bg-neutral-50 gap-2 p-8 w-full rounded-card",
  groupActions: "flex items-center justify-end gap-small w-[12rem]",
  shrinkShade: "absolute bottom-0 left-0 right-0 h-60 z-10 bg-shrinkShade",
  repeatButton:
    "absolute bottom-[-3.6rem] max-w-[100%] flex items-center pl-[1.6rem] pr-[0.5rem] py-[0.3rem] rounded cursor-not-allowed text-neutral-400 bg-white border-solid border border-neutral-400",
};

export const getGroupCardClassNames = ({ current }: { current?: boolean }) => {
  const baseStyles = "bg-neutral-20 flex flex-col px-2 pl-0 pr-16 rounded-card";
  const currentStyles = "border-2 border-cyan-240 shadow-modal";

  return clsx(baseStyles, current && currentStyles);
};

export const getGroupFieldListClassNames = ({
  shrinking,
}: {
  shrinking?: boolean;
}) => {
  const baseStyles = "relative overflow-visible";
  const shrinkingStyles = "max-h-[36rem] overflow-hidden";

  return clsx(baseStyles, shrinking && shrinkingStyles);
};

export const getGroupFieldContainerClassNames = ({
  shrinking,
  index,
}: {
  shrinking?: boolean;
  index: number;
}) => {
  const baseStyles =
    "relative transition-[margin-top] duration-200 ease-linear mt-0 z-[unset]";
  const marginStyles = index ? "mt-[-12rem]" : "mt-[-5rem]";
  const zIndexStyles = ["z-[5]", "z-[4]", "z-[3]", "z-[2]", "z-1", "z-0"];

  return clsx(
    baseStyles,
    shrinking && marginStyles,
    shrinking && zIndexStyles[index],
  );
};

export const getDroppablePlaceholderClassNames = ({
  isDraggingOver,
}: {
  isDraggingOver?: boolean;
}) => {
  const baseStyles =
    "flex items-center justify-center py-6 border-dashed border border-neutral-200 bg-neutral-20 rounded-[1rem] mb-8";
  const draggingOverStyles =
    "border-blue-560 bg-blue-60 shadow-[0_0_0_4px_rgba(174,211,254,0.6)]";
  const activeStyles =
    "active:border-blue-560 active:shadow-[0_0_0_4px_rgba(174,211,254,0.6)]";

  return clsx(baseStyles, activeStyles, isDraggingOver && draggingOverStyles);
};
