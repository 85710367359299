import { DragDropContext } from "@hello-pangea/dnd";
import { useEffect, useRef, useState } from "react";

import { useFeatureFlags } from "@smart/bridge-feature-flags-dom";
import { aiFillSectionUri, SectionNS } from "@smart/bridge-types-basic";
import { loadLocale } from "@smart/itops-locale-dom";
import { useProviderInfo } from "@smart/itops-smokeball-components-dom";
import { useQueryMatterFields } from "@smart/manage-gql-client-dom";

import { AIFillSection } from "./ai-fill-section";
import { useConditions } from "./condition/conditions";
import { EditConditionsModal } from "./condition/edit-conditions-modal";
import { useSectionItemOrder } from "./section-item-order";
import { FormLoadingStatus } from "../../create-form/creation-status";
import { DuplicatingFormModal } from "../../list-forms/duplicating";
import { useUpdateData } from "../hooks";
import { FieldComponents, SectionNavigation } from "../navigation";
import { Questions } from "../questions";
import { MappedFieldsModal } from "../questions/mapped-fields-modal";
import { ChangeMappedFieldTypeModal } from "../questions/mapped-fields-modal/change-mapped-field-type-modal";
import { MoveFieldConfirmationModal } from "../questions/move-field-confirmation-modal";
import {
  EditableSection,
  FieldTemplate,
  FormInfo,
  GqlField,
  GqlFieldValues,
  GqlForm,
  GqlGroup,
  GqlMatterLayout,
  GqlMatterType,
  GqlSection,
  UpdateAIFillSettings,
} from "../types";
import { getOrderedSectionItems } from "../utils/section-items";

type EditViewProps = {
  formUri: string;
  form?: GqlForm | null;
  sections?: GqlSection[] | null;
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
  loading: {
    team: boolean;
    form: boolean;
    sections: boolean;
    groups: boolean;
    fields: boolean;
    matterTypes: boolean;
    matterLayouts: boolean;
  };
  loadingStatus: FormLoadingStatus;
  matterTypes?: GqlMatterType[];
  matterLayouts?: GqlMatterLayout[];
  loadMatterFields: ReturnType<typeof useQueryMatterFields>;
  createForm: () => Promise<void>;
  updateAIFillSettings: UpdateAIFillSettings;
  canUpdate: boolean;
  setTab: React.Dispatch<React.SetStateAction<"questions" | "preview">>;
  teamForms?: FormInfo[];
  editFormLastSectionUri: [
    string | undefined,
    React.Dispatch<React.SetStateAction<string | undefined>>,
  ];
};

const EditView = ({
  formUri,
  form,
  sections,
  fields,
  groups,
  loading,
  matterTypes,
  matterLayouts,
  loadingStatus,
  loadMatterFields,
  createForm,
  updateAIFillSettings,
  canUpdate,
  setTab,
  teamForms,
  editFormLastSectionUri,
}: EditViewProps) => {
  const newSectionUriRef = useRef(SectionNS.generateUri());
  const sectionsToUse: EditableSection[] = sections?.length
    ? sections
    : [{ uri: newSectionUriRef.current }];
  const firstSectionUri = form?.aiFillSettings?.allowAiFill
    ? aiFillSectionUri
    : sectionsToUse[0].uri;
  const [currentSectionUri, setCurrentSectionUri] = useState<string>(
    editFormLastSectionUri[0] || firstSectionUri,
  );
  const [selectedFieldToMap, setSelectedFieldToMap] = useState<
    GqlFieldValues | undefined
  >(undefined);
  const providerInfo = useProviderInfo();
  const [isDraggingItem, setIsDraggingItem] = useState(false);
  const [isValidSection, setIsValidSection] = useState(false);
  const [droppedOnSectionUri, setDroppedOnSectionUri] = useState<
    string | undefined
  >();
  const { appointmentField, billingField } = useFeatureFlags();
  const excludingFieldTemplates: FieldTemplate[] = [];
  if (!appointmentField) excludingFieldTemplates.push({ type: "appointment" });
  if (!billingField) excludingFieldTemplates.push({ type: "payment" });

  const {
    editingCondition,
    setEditingCondition,
    checkHasConditions,
    getNumberOfConditions,
    conditionsMap,
    createLinksMap,
  } = useConditions({
    sections,
    groups,
    fields,
  });

  const sectionFields = fields?.filter(
    (f) => f.values.sectionUri === currentSectionUri,
  );

  const sectionGroups = groups?.filter(
    (g) => g.values.sectionUri === currentSectionUri,
  );

  const sectionItems = getOrderedSectionItems({
    sectionUri: currentSectionUri,
    fields: fields || [],
    groups: groups || [],
  });

  useEffect(() => {
    editFormLastSectionUri[1](currentSectionUri);
  }, [currentSectionUri]);

  const {
    addMappedFields,
    addGroup,
    updateGroup,
    changeGroupOrder,
    deleteGroup,
    addField,
    updateField,
    mergeUpdateField,
    mergeUpdateGroup,
    changeFieldOrder,
    deleteField,
    updateSection,
    updateFormSection,
    updateAllSections,
    newItemUri,
    setNewItemUri,
    updatingItems,
    updateFieldGroup,
    updateAIUserFeedback,
  } = useUpdateData({
    formUri,
    form,
    sectionFields,
    sectionGroups,
    createForm,
    currentSectionUri,
    setCurrentSectionUri,
    shouldCreateInitialSection: !sections?.length,
  });

  const {
    questionEditMode,
    mappedFieldsOptions,
    setMappedFieldsOptions,
    onDragEnd,
    onBeforeCapture,
    pendingDropAction,
    cancelDropAction,
    confirmDropAction,
  } = useSectionItemOrder({
    sectionFields,
    addField,
    addGroup,
    changeFieldOrder,
    changeGroupOrder,
    sectionItems,
    updateFieldGroup,
    fields,
    groups,
    mergeUpdateField,
    updateGroup,
    droppedOnSectionUri,
    setDroppedOnSectionUri,
  });
  useEffect(() => {
    if (
      sectionsToUse?.length &&
      !sectionsToUse.map((s) => s.uri).includes(currentSectionUri)
    ) {
      if (form?.aiFillSettings?.allowAiFill) {
        setCurrentSectionUri(aiFillSectionUri);
      } else {
        setCurrentSectionUri(sectionsToUse[0].uri);
      }
    }
  }, [sections?.length, formUri]);

  const disabled =
    loadingStatus === "checking" ||
    loadingStatus === "timedOut" ||
    form?.aiUserFeedback?.status === "generating" ||
    form?.aiUserFeedback?.status === "generated";

  return (
    <div className="flex flex-1 overflow-hidden">
      <DragDropContext
        onDragEnd={async (r) => {
          await onDragEnd(r);
          setIsDraggingItem(false);
        }}
        onDragStart={() => setIsDraggingItem(true)}
        onBeforeCapture={onBeforeCapture}
      >
        <div className="overflow-y-auto">
          <SectionNavigation
            form={form}
            sections={sectionsToUse}
            currentSectionUri={currentSectionUri}
            disabled={disabled}
            loading={loading}
            setCurrentSectionUri={setCurrentSectionUri}
            updateAllSections={updateAllSections}
            isDraggingItem={isDraggingItem}
            setDroppedOnSectionUri={setDroppedOnSectionUri}
            setIsValidSection={setIsValidSection}
            isValidSection={isValidSection}
            aiFillSettings={form?.aiFillSettings}
            updateAIFillSettings={updateAIFillSettings}
          />
        </div>
        {currentSectionUri === aiFillSectionUri ? (
          <AIFillSection
            aiFillSettings={form?.aiFillSettings}
            updateAIFillSettings={updateAIFillSettings}
          />
        ) : (
          <Questions
            mode={questionEditMode}
            loading={loading}
            form={form}
            section={sectionsToUse.find((s) => s.uri === currentSectionUri)}
            sectionItems={sectionItems}
            editingItemUri={newItemUri}
            updatingItems={updatingItems}
            mergeUpdateField={mergeUpdateField}
            deleteField={deleteField}
            updateGroup={updateGroup}
            deleteGroup={deleteGroup}
            updateSection={updateFormSection}
            onUpdatingFieldDone={() => setNewItemUri(undefined)}
            matterLayouts={matterLayouts}
            matterTypes={matterTypes}
            updateAIUserFeedback={updateAIUserFeedback}
            loadMatterFields={loadMatterFields}
            loadingStatus={loadingStatus}
            creationStatus={form?.creationStatus}
            conditionActions={{
              checkHasConditions,
              setEditingCondition,
              getNumberOfConditions,
            }}
            setSelectedFieldToMap={setSelectedFieldToMap}
            isValidSection={isValidSection}
            excludingFieldTemplates={excludingFieldTemplates}
          />
        )}

        <FieldComponents
          disabled={
            disabled || loading.sections || loading.groups || loading.fields
          }
          excluding={excludingFieldTemplates}
        />
        <MappedFieldsModal
          createFields={addMappedFields}
          onClose={() => setMappedFieldsOptions(undefined)}
          matterLayouts={matterLayouts}
          mappedFieldsOptions={mappedFieldsOptions}
          matterTypes={matterTypes}
          loadMatterFields={loadMatterFields}
          fields={fields || []}
          groups={groups || []}
          loading={loading.matterLayouts || loading.matterTypes}
        />
        {selectedFieldToMap && (
          <ChangeMappedFieldTypeModal
            updateField={updateField}
            onClose={() => setSelectedFieldToMap(undefined)}
            matterLayouts={matterLayouts}
            selectedFieldToMap={selectedFieldToMap}
            matterTypes={matterTypes}
            loadMatterFields={loadMatterFields}
            fields={fields || []}
            groups={groups || []}
            loading={loading.matterLayouts || loading.matterTypes}
          />
        )}
        <EditConditionsModal
          conditionsMap={conditionsMap}
          currentField={editingCondition}
          setCurrentField={setEditingCondition}
          mergeUpdateField={mergeUpdateField}
          mergeUpdateGroup={mergeUpdateGroup}
          updateSection={updateSection}
          createLinksMap={createLinksMap}
          fields={fields || []}
          groups={groups || []}
          sections={sections || []}
        />
        <DuplicatingFormModal
          duplicatingForm={canUpdate ? undefined : form}
          forms={teamForms || []}
          onClose={() => setTab("preview")}
          header={`${providerInfo?.label || "Smokeball"} forms cannot be edited`}
          description={`Duplicate this form to ${loadLocale().country === "US" ? "customize" : "customise"}`}
        />
        <MoveFieldConfirmationModal
          action={pendingDropAction}
          onCancel={cancelDropAction}
          onConfirm={confirmDropAction}
        />
      </DragDropContext>
    </div>
  );
};

export { EditView, EditViewProps };
