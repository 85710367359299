import { useEffect, useState } from "react";

import {
  EditableText,
  Icon,
  MultipleLabel,
} from "@smart/itops-sb-design-system-dom";

import { EditFormInfoModal } from "./edit-form-info-modal";
import { EditingFormInfo } from "../../list-forms/form";
import {
  GqlField,
  GqlFormCategory,
  GqlGroup,
  GqlMatterType,
  GqlSetting,
} from "../../types";

type FormInfoProps = {
  formTitle: string;
  matterTypeNames: string[];
  matterTypeLocations: string[];
  disabled?: boolean;
  isMatterSpecific?: boolean;
  loading: {
    form: boolean;
    matterTypes: boolean;
  };
  onFormTitleUpdate: (title: string) => Promise<void>;
  validateFormTitle: (title: string) => boolean;
  updateFormInfo: (values: EditingFormInfo) => Promise<void>;
  setting?: GqlSetting | null;
  category?: GqlFormCategory;
  matterTypes: GqlMatterType[];
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
};

const FormInfo = ({
  formTitle,
  matterTypeNames,
  matterTypeLocations,
  disabled,
  isMatterSpecific,
  loading,
  onFormTitleUpdate,
  validateFormTitle,
  updateFormInfo,
  setting,
  category,
  matterTypes,
  groups,
  fields,
}: FormInfoProps) => {
  const [editingTitle, setEditingTitle] = useState(formTitle);
  const [titleError, setTitleError] = useState<string>();
  const [enterEditing, setEnterEditing] = useState(false);
  const [openEditFormInfoModal, setOpenEditFormInfoModal] = useState(false);

  const isLoading =
    (matterTypeNames.length === 0 || matterTypeLocations.length === 0) &&
    (loading.matterTypes || loading.form);

  const labels = [matterTypeNames, matterTypeLocations];

  const onEditingTitleComplete = async () => {
    setEnterEditing(false);

    if (!editingTitle) {
      setTitleError("Please enter a name.");
      return;
    }

    if (!validateFormTitle(editingTitle)) {
      setTitleError(
        "A form with this name already exists. Please enter another name.",
      );
      return;
    }

    await onFormTitleUpdate(editingTitle);
  };

  useEffect(() => {
    setEditingTitle(formTitle);
  }, [formTitle]);

  return (
    <div
      data-testid="form-title-edit"
      className="flex flex-col gap-small flex-1"
    >
      <div className="max-w-[55rem]">
        <EditableText
          text={editingTitle}
          placeholder="Form title"
          error={titleError}
          editing={enterEditing}
          loading={loading.form && !formTitle}
          disabled={disabled}
          dataTestId="editor-form-title"
          onChange={(value) => {
            setTitleError(undefined);
            setEditingTitle(value);
          }}
          onComplete={onEditingTitleComplete}
          fontSize="h3"
          fontWeight="semibold"
        />
      </div>

      <button
        className="group flex items-center gap-small ml-[1.1rem] text-ui text-neutral-400 font-semibold"
        data-testid="edit-form-info-button"
        onClick={() => setOpenEditFormInfoModal(true)}
        disabled={disabled || isMatterSpecific}
        type="button"
      >
        <MultipleLabel
          labels={labels}
          placeholders={["Matter types", "Labels"]}
          loading={isLoading}
          maxVisibleItems={2}
        />
        <Icon name="regularPencil" className="hidden group-hover:block" />
      </button>
      {openEditFormInfoModal && (
        <EditFormInfoModal
          loading={loading.form || loading.matterTypes}
          open={openEditFormInfoModal}
          onClose={() => setOpenEditFormInfoModal(false)}
          updateFormInfo={updateFormInfo}
          setting={setting}
          category={category}
          matterTypeLocations={matterTypeLocations}
          matterTypes={matterTypes}
          groups={groups}
          fields={fields}
        />
      )}
    </div>
  );
};

export { FormInfo, FormInfoProps };
