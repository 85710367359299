import { useState } from "react";

import { DragNDropList } from "@smart/itops-components-dom";
import { Button } from "@smart/itops-sb-design-system-dom";

import { DraggableOptionItem, OptionsSharedProps } from "./option-item";
import { OtherOptionItem } from "./other-item";

const MAX_OPTIONS_TO_DISPLAY = 8;

type EditOptionsProps = OptionsSharedProps & {
  addOption: (options: "text" | "other") => void;
  moveOption: (sourceIndex: number, destinationIndex: number) => Promise<void>;
  openOptionModal?: () => void;
  isShowingOtherOption: boolean;
  isOptionsFixed?: boolean;
};

export const EditOptions = ({
  optionsFields,
  type,
  addOption,
  isShowingOtherOption,
  isOptionsFixed,
  removeOption,
  updateOption,
  moveOption,
  openOptionModal,
}: EditOptionsProps) => {
  const [newOptionIndex, setNewOptionIndex] = useState<number>();
  const displayOverflow =
    openOptionModal && optionsFields.length > MAX_OPTIONS_TO_DISPLAY;

  return (
    <div>
      <DragNDropList
        id="draggable-options"
        className="flex flex-col mx-0 mb-[0.4rem]"
        items={
          displayOverflow
            ? optionsFields.slice(0, MAX_OPTIONS_TO_DISPLAY)
            : optionsFields
        }
        itemKey="id"
        render={DraggableOptionItem}
        renderProps={{
          type,
          removeOption,
          optionsFields,
          updateOption: async (index: number, label: string | undefined) => {
            await updateOption(index, label);
            setNewOptionIndex(undefined);
          },
          newOptionIndex,
        }}
        onMove={async ({ source, destination }) => {
          await moveOption(source.index, destination.index);
        }}
      />
      <OtherOptionItem
        type={type}
        isShowingOtherOption={isShowingOtherOption}
        removeOption={removeOption}
      />
      {displayOverflow ? (
        <Button
          text={`View or edit all ${optionsFields.length} options`}
          variant="highlight"
          onClick={openOptionModal}
          className="ml-[4px]"
        />
      ) : (
        <div className="flex gap-small my-[1.6rem]">
          <Button
            text="Add choice"
            leftIcon="regularPlus"
            variant="primarySubtle"
            onClick={() => {
              addOption("text");
              setNewOptionIndex(optionsFields.length);
            }}
            size="small"
            disabled={isOptionsFixed}
          />
          <Button
            text="Add other"
            leftIcon="regularPlus"
            variant="primarySubtle"
            onClick={() => {
              addOption("other");
            }}
            disabled={isOptionsFixed || isShowingOtherOption}
            size="small"
          />
        </div>
      )}
    </div>
  );
};
