import { useEffect, useState } from "react";

import {
  FieldLinkType,
  LinkConditionType,
  fieldTypeLabel,
} from "@smart/bridge-types-basic";
import { Avatar, Button, Icon, Modal } from "@smart/itops-sb-design-system-dom";
import { entriesOf, v4 } from "@smart/itops-utils-basic";
import { useUpdateSection } from "@smart/manage-gql-client-dom";

import { ConditionBox } from "./condition-box";
import { AffectedByList, EffectingList } from "./condition-list";
import { hasOptions } from "./condition-type";
import {
  ConditionItem,
  getItemLabel,
  getItemType,
  isConditionField,
} from "./utils";
import { useUpdateData } from "../../../hooks";
import {
  GqlField,
  GqlFieldValues,
  GqlGroup,
  GqlGroupValues,
  GqlSection,
  GqlSectionValues,
} from "../../../types";
import { Condition, ConditionMap } from "../conditions";
import { validateConditions } from "../validation";

type EditConditionsModalProps = {
  currentField: ConditionItem | null;
  setCurrentField: (field: ConditionItem | null) => void;
  conditionsMap: ConditionMap;
  sections: GqlSection[];
  groups: GqlGroup[];
  fields: GqlField[];
  mergeUpdateField: ReturnType<typeof useUpdateData>["mergeUpdateField"];
  mergeUpdateGroup: ReturnType<typeof useUpdateData>["mergeUpdateGroup"];
  updateSection: ReturnType<typeof useUpdateSection>[0];
  createLinksMap: (
    uri: string,
    conditions: Condition[],
  ) => Record<string, FieldLinkType[]>;
};

const EditConditionsModal = ({
  currentField,
  setCurrentField,
  conditionsMap,
  sections,
  groups,
  fields,
  mergeUpdateField,
  mergeUpdateGroup,
  updateSection,
  createLinksMap,
}: EditConditionsModalProps) => {
  const [errors, setErrors] = useState<Record<string, string>>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentConditions, setCurrentConditions] = useState<Condition[]>([]);

  useEffect(() => {
    if (currentField) {
      const newConditions = conditionsMap.affect[currentField.uri] || [];
      setCurrentConditions(newConditions);
      setErrors(validateConditions(newConditions));
    }
  }, [currentField]);

  const removeCondition = (id: string) => {
    setCurrentConditions((prevConditions) =>
      prevConditions.filter((condition) => condition.id !== id),
    );
  };

  const updateCondition = (
    id: string,
    updatedValues: {
      condition?: LinkConditionType;
      value?: string;
      affectedItem?: GqlFieldValues | GqlGroupValues | GqlSectionValues;
    },
  ) => {
    setCurrentConditions((prevConditions) =>
      prevConditions.map((condition) =>
        condition.id === id ? { ...condition, ...updatedValues } : condition,
      ),
    );
  };

  const addCondition = () => {
    if (isConditionField(currentField)) {
      const newCondition: Condition = {
        id: v4(),
        condition: "equalTo",
        value: "",
        affectingField: currentField,
        affectedItem: undefined,
        hide: false,
      };

      setCurrentConditions([...currentConditions, newCondition]);
    }
  };

  useEffect(() => {
    if (errors) setErrors(validateConditions(currentConditions));
  }, [currentConditions]);

  const onSaveConditions = async () => {
    if (!currentField) return;
    const foundErrors = validateConditions(currentConditions);
    if (foundErrors) {
      setErrors(foundErrors);
      return;
    }

    const linksMap = createLinksMap(currentField?.uri, currentConditions);
    const updateLinksPromises = entriesOf(linksMap).map(([uri, links]) => {
      const itemType = getItemType(uri);
      if (itemType === "Section") {
        const existing = sections?.find((s) => s.uri === uri);
        if (!existing) return null;

        return updateSection({
          variables: {
            uri,
            formUri: existing.values.formUri,
            order: existing.values.order,
            fields: {
              title: existing.values.title,
              links,
            },
          },
        });
      }

      if (itemType === "Group") {
        const existing = groups?.find((s) => s.uri === uri);
        if (!existing) return null;

        return mergeUpdateGroup({
          current: existing.values,
          incoming: {
            links,
          },
        });
      }

      if (itemType === "Field") {
        const existing = fields?.find((s) => s.uri === uri);
        if (!existing) return null;

        return mergeUpdateField({
          current: existing.values,
          incoming: {
            links,
          },
        });
      }

      return null;
    });

    try {
      setErrors(undefined);
      setIsLoading(true);
      await Promise.all(updateLinksPromises);
      setCurrentField(null);
    } catch (error) {
      console.error(`Error in saving conditions: `, error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    if (!isLoading) {
      setCurrentField(null);
      setCurrentConditions([]);
      setErrors(undefined);
    }
  };
  const currentItemType = getItemType(currentField?.uri) || "Field";

  const hasNoOptions = !hasOptions(currentField);
  const isDisabled = !!(
    hasNoOptions ||
    groups.find((g) => g.uri === (currentField as GqlFieldValues)?.groupUri)
      ?.values.repeatable
  );

  const getConditionNote = (): string => {
    switch (currentItemType) {
      case "Section":
        return "Conditions cannot be added to Sections.";
      case "Group":
        return "Conditions cannot be added to Groups.";
      case "Field": {
        const getFieldConditionNote = () => {
          if (hasNoOptions)
            return `Conditions cannot be added to ${fieldTypeLabel[(currentField as GqlFieldValues).type]} fields.`;
          return isDisabled
            ? "Conditions cannot be added to repeatable fields. Existing conditions will not take effect."
            : "";
        };
        return getFieldConditionNote();
      }
      default:
        return "";
    }
  };

  return (
    <Modal
      loading={isLoading}
      open={!!currentField}
      onClose={onClose}
      header={{
        text: `Add conditions for "${getItemLabel(currentField)}"`,
        icon: "solidArrowsSplitUpAndLeft",
        iconBgColor: "bg-neutral-50",
      }}
      footer={{
        buttons: [
          {
            text: "Cancel",
            variant: "secondarySubtle",
            onClick: onClose,
          },
          {
            text: "Save",
            onClick: onSaveConditions,
            disabled: !!errors,
          },
        ],
      }}
      size="large"
      contentBgColor="bg-neutral-50"
    >
      {currentField && (
        <>
          <div className="flex gap-4 my-[0.6rem] mx-0 w-full">
            <AffectedByList
              itemType={currentItemType}
              setCurrentField={setCurrentField}
              conditions={conditionsMap.affectedBy[currentField.uri]}
            />
            <EffectingList
              itemType={currentItemType}
              setCurrentField={setCurrentField}
              conditions={conditionsMap.affect[currentField.uri]}
            />
          </div>
          {currentConditions?.flatMap((condition, index) => {
            const conditionBox = (
              <ConditionBox
                currentField={currentField as GqlFieldValues}
                sections={sections.map((s) => s.values)}
                groups={groups.map((g) => g.values)}
                fields={fields.map((f) => f.values)}
                key={condition.id}
                condition={condition}
                error={errors?.[condition.id]}
                updateCondition={updateCondition}
                removeCondition={removeCondition}
              />
            );
            const separator = (
              <div>
                <Avatar
                  size={40}
                  text="And"
                  bgColor="bg-orange-200"
                  className="w-[5.9rem] mb-[1.6rem]"
                />
              </div>
            );
            return index < currentConditions.length - 1
              ? [conditionBox, separator]
              : [conditionBox];
          })}
          {isDisabled && (
            <div className="flex items-center gap-2 text-neutral-400">
              <Icon name="regularCircleInfo" />
              <span>{getConditionNote()}</span>
            </div>
          )}
          <div>
            <Button
              leftIcon="regularPlus"
              text="Add condition"
              variant="primarySubtle"
              onClick={addCondition}
              disabled={isDisabled}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export { EditConditionsModal };
