import { useState } from "react";

import {
  FileFieldItem,
  FileInputField,
  FileUpload,
  Label,
  uploadFile,
  UploadStatus,
} from "@smart/itops-sb-design-system-dom";
import {
  checkIsPdfEncrypted,
  checkIsWordDocEncrypted,
} from "@smart/itops-utils-basic";
import { useUploadQuestionGenerationFiles } from "@smart/manage-gql-client-dom";

const maxFileSizeInBytes = 5 * 1028 * 1028;

type UploadQuestionsProps = {
  name: string;
  onChange: (v: string | undefined) => void;
  error?: boolean;
  supportedFileExtensions?: string[];
  id?: string;
  disabled?: boolean;
  label?: string;
  mandatory?: boolean;
  message?: string;
};
export const UploadQuestions = ({
  name,
  onChange,
  error,
  supportedFileExtensions,
  id,
  disabled,
  label,
  mandatory,
  message,
}: UploadQuestionsProps) => {
  const errorId = `${name}-error`;

  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>("notUploaded");
  const [uploadQuestionGenerationFiles, { data, reset }] =
    useUploadQuestionGenerationFiles();

  const buildFileLimitInfo = (sizeInBytes: number, extensions: string[]) => {
    const sizeInMB = Math.floor(sizeInBytes / (1028 * 1028));
    const formatSet = new Set<string>();

    extensions.forEach((ext) => {
      const nameOnly = ext.split(".").pop();
      const converted = nameOnly === "jpg" ? "jpeg" : nameOnly;
      if (converted) formatSet.add(converted.toUpperCase());
    });
    const formatAsArray = Array.from(formatSet);
    const last = formatAsArray.pop();

    const restLimit = formatAsArray.length
      ? ` - ${formatAsArray.join(", ")}`
      : "";
    const lastLimit =
      formatAsArray.length && last
        ? ` or ${last}`
        : `${last ? ` - ${last}` : ""}`;

    return `Maximum ${sizeInMB}MB${restLimit}${lastLimit}`;
  };

  const validateFileInput = async (
    inputFile: File | null | undefined,
  ): Promise<boolean> => {
    if (!inputFile) return false;

    const extension = inputFile.name.split(".").pop();
    if (supportedFileExtensions?.length) {
      if (
        !supportedFileExtensions.includes(`.${(extension || "").toLowerCase()}`)
      ) {
        setUploadStatus("unsupportedFileType");
        return false;
      }
    }

    if (inputFile.size > maxFileSizeInBytes) {
      setUploadStatus("exceedSizeLimit");
      return false;
    }

    const checkIsFileEncrypted = async () => {
      switch (extension?.toLowerCase()) {
        case "pdf":
          return checkIsPdfEncrypted(inputFile);
        case "doc":
        case "docx":
          return checkIsWordDocEncrypted(inputFile);
        default:
          return false;
      }
    };
    if (await checkIsFileEncrypted()) {
      setUploadStatus("fileEncrypted");
      return false;
    }

    return true;
  };

  const onSelect = async (files: FileList | null | undefined) => {
    if (files?.length) {
      setFile(files[0]);
      if (!(await validateFileInput(files[0]))) return;

      const result = await uploadQuestionGenerationFiles({
        variables: { fileNames: [files[0].name] },
      });
      onChange(result.data?.uploadQuestionGenerationFiles[0].key);
    }
  };

  return (
    <Label
      text={label}
      mandatory={mandatory}
      disabled={disabled}
      name={id}
      error={!!error}
      message={message}
    >
      {file ? (
        <FileFieldItem
          onRemove={() => {
            onChange(undefined);
            setFile(null);
            setUploadStatus("notUploaded");
            reset();
          }}
          upload={uploadFile}
          downloadUrl={undefined}
          uploadUrl={data?.uploadQuestionGenerationFiles[0].uploadUrl}
          file={file}
          fileName={file.name}
          uploadStatus={uploadStatus}
          onStatusChange={setUploadStatus}
          fileIndex={0}
          fieldId="field:0"
          uploadFileProps={{
            onProgress: () => {},
            onUploadStatusChange: () => {},
            initialise: () => {},
            getUploadStates: () => [],
          }}
          fileSizeLimitMegaByte={5}
          maxNumOfFiles={1}
        />
      ) : (
        <>
          <FileUpload
            id={name}
            errorId={errorId}
            error={error ? message : undefined}
            sizeLimit={buildFileLimitInfo(
              maxFileSizeInBytes,
              supportedFileExtensions || [],
            )}
            onSelect={onSelect}
          />
          <FileInputField
            id={name}
            name={name}
            aria-invalid={!!error}
            aria-errormessage={error ? errorId : undefined}
            type="file"
            data-testid="file-upload"
            accept={
              supportedFileExtensions
                ? supportedFileExtensions.join(",")
                : undefined
            }
            onChange={async (e) => {
              e.preventDefault();
              await onSelect(e.target.files);
            }}
          />
        </>
      )}
    </Label>
  );
};
