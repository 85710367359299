import clsx from "clsx";

import { Badge, Button, Tooltip } from "@smart/itops-sb-design-system-dom";

type ConditionsButtonProps = {
  numberOfConditions?: number;
  onClick: () => void;
  size?: "default" | "small";
  max?: number;
  dataTestId?: string;
};

export const ConditionsButton = ({
  numberOfConditions,
  onClick,
  max = 9,
  size,
  dataTestId,
}: ConditionsButtonProps) => {
  const isOverMax = numberOfConditions && numberOfConditions > max;
  const label = isOverMax ? (
    <span className="flex ml-[0.3rem]">
      {max}
      <span className="text-[0.9rem]">+</span>
    </span>
  ) : (
    `${numberOfConditions}`
  );

  return (
    <Tooltip
      className="flex items-center"
      text="Show/hide based on answer"
      placement="top-end"
      offsetBy={7}
    >
      <div className="relative">
        {!!numberOfConditions && (
          <div className="absolute top-[0.3rem] left-[0.3rem] -translate-x-[50%] -translate-y-[35%] z-1">
            <Badge
              label={label}
              isRound
              theme="destructive"
              isShade
              className="w-[1.6rem] h-[1.6rem] leading-[1.6rem] rounded-full"
              dataTestId="number-of-conditions-badge"
            />
          </div>
        )}
        <Button
          leftIcon={
            numberOfConditions
              ? "solidArrowsSplitUpAndLeft"
              : "regularArrowsSplitUpAndLeft"
          }
          variant="secondarySubtle"
          className={clsx(
            numberOfConditions ? "bg-black/10" : undefined,
            "focus:before:inset-0",
          )}
          onClick={onClick}
          size={size}
          dataTestId={dataTestId || "conditions-button"}
        />
      </div>
    </Tooltip>
  );
};
