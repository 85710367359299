import { Droppable } from "@hello-pangea/dnd";
import clsx from "clsx";

const wrapperClassNames = "my-0 mx-auto max-w-screen-readable w-full relative";

const contentClassNames =
  "absolute w-full h-full flex items-center justify-center pointer-events-none";

const getDraggingClassNames = (isDraggingOver: boolean) =>
  clsx(
    "flex-1 max-w-full rounded-small transition ease-out duration-200 border border-dashed",
    isDraggingOver
      ? "bg-blue-60 border-blue-460"
      : "bg-neutral-20 border-neutral-200",
  );

type SeparatorProps = {
  index: number;
  groupUri?: string | null;
  isFieldReordering?: boolean;
};

const Separator = ({ index, groupUri, isFieldReordering }: SeparatorProps) => (
  <Droppable
    droppableId={
      groupUri ? `${groupUri}|${index}` : `droppable-field-separator|${index}`
    }
  >
    {(provider, snapshot) => (
      <div
        className={clsx(
          wrapperClassNames,
          isFieldReordering && "transition ease-out duration-200 opacity-0",
        )}
      >
        <div className={contentClassNames}>
          <div
            className={clsx(
              getDraggingClassNames(snapshot.isDraggingOver),
              "h-[0.8rem]",
            )}
          />
        </div>
        <div
          className="w-[12.5rem] h-[1.6rem] my-0 mx-auto"
          ref={provider.innerRef}
        />
        <div className="hidden">{provider.placeholder}</div>
      </div>
    )}
  </Droppable>
);

export const InitialDroppable = ({ index }: { index: number }) => (
  <Droppable droppableId={`droppable-field-separator|${index}`}>
    {(provider, snapshot) => (
      <div className={wrapperClassNames}>
        <div className={contentClassNames}>
          <div
            className={clsx(
              getDraggingClassNames(snapshot.isDraggingOver),
              "h-full",
            )}
          >
            <div className="flex items-center justify-center text-ui h-full text-neutral-500">
              Drag and drop ‘Components’ to build your form.
            </div>
          </div>
        </div>
        <div
          className="w-[12.5rem] h-[4.4rem] my-0 mx-auto"
          ref={provider.innerRef}
        />
        <div className="hidden">{provider.placeholder}</div>
      </div>
    )}
  </Droppable>
);

export { Separator };
