import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { FormNS, globalTeamUri } from "@smart/bridge-types-basic";
import { Modal, TextFieldInput } from "@smart/itops-sb-design-system-dom";
import { extractId } from "@smart/itops-types-basic";
import { useCopyForm, useUpdateForm } from "@smart/manage-gql-client-dom";

import { FormInfo } from "../edit-form-v2/types";
import { GqlForm } from "../types";

export type DuplicatingFormModalProps = {
  duplicatingForm: GqlForm | undefined | null;
  forms: FormInfo[];
  onClose: () => void;
  header?: string;
  description?: string;
};

export const DuplicatingFormModal = ({
  duplicatingForm,
  forms,
  onClose,
  header,
  description,
}: DuplicatingFormModalProps) => {
  const navigate = useNavigate();
  const [copyForm] = useCopyForm();
  const [updateForm] = useUpdateForm();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { formName: "" },
    resolver: zodResolver(
      z.object({
        formName: z
          .string()
          .min(1, "Please enter a form name")
          .refine(
            (value) => {
              const found = forms
                .filter((f) => f.teamUri !== globalTeamUri)
                .find((f) => value === f.formTitle);
              return !found;
            },
            {
              message: "A form with this name already exists",
            },
          ),
      }),
    ),
  });

  const onSubmit = handleSubmit(async (values) => {
    if (!duplicatingForm) return;
    const newFormUri = FormNS.generateUri();

    await updateForm({
      variables: {
        uri: newFormUri,
        fields: {
          source: "duplicate",
          title: values.formName,
          creationStatus: "duplicating",
          category: duplicatingForm.formCategory,
          response: duplicatingForm.rawForm.values.response,
        },
      },
    }).catch(console.error);

    navigate(`/embedded/builder/${extractId(newFormUri)}`);

    await copyForm({
      variables: {
        uri: duplicatingForm.uri,
        newUri: newFormUri,
        newTitle: values.formName,
      },
    }).catch(console.error);
  });

  useEffect(() => {
    if (duplicatingForm?.formTitle)
      reset({ formName: `${duplicatingForm.formTitle} - Duplicate` });
  }, [duplicatingForm]);

  return (
    <Modal
      onClose={onClose}
      open={!!duplicatingForm}
      loading={isSubmitting}
      header={{
        icon: "solidCopy",
        iconBgColor: "bg-cyan-140",
        text:
          header ||
          `Duplicate ${duplicatingForm && duplicatingForm.formCategory} form`,
      }}
      footer={{
        buttons: [
          {
            text: "Cancel",
            variant: "secondarySubtle",
            onClick: onClose,
          },
          {
            text: "Duplicate form",
            variant: "primaryDefault",
            onClick: onSubmit,
          },
        ],
      }}
    >
      <p>
        {description ||
          `To duplicate "${duplicatingForm?.formTitle}", please enter a name for your new form.`}
      </p>
      <Controller
        control={control}
        name="formName"
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <TextFieldInput
            id={field.name}
            label="Form name"
            message={
              fieldState.error?.message ??
              "Enter a unique name for your new form"
            }
            error={!!fieldState.error}
            value={field.value}
            onChange={field.onChange}
            mandatory
          />
        )}
      />
    </Modal>
  );
};
