import { useMemo } from "react";

import { Select } from "@smart/itops-sb-design-system-dom";
import { fromEntries, sortField } from "@smart/itops-utils-basic";

import { ConditionItem } from "./utils";
import {
  GqlFieldValues,
  GqlGroupValues,
  GqlSectionValues,
  ItemType,
} from "../../../types";

const prefixOrder = <I extends { order: string }>(
  items: I[],
  getPrefixes: ((item: I) => string | undefined)[],
) =>
  items.map((item) => {
    const prefixes = getPrefixes
      .map((get) => get(item))
      .filter(Boolean)
      .join("");

    return { ...item, order: `${prefixes}${item.order}` };
  });

type AffectedItemProps = {
  label: string;
  itemType: ItemType | undefined;
  sections: GqlSectionValues[];
  groups: GqlGroupValues[];
  fields: GqlFieldValues[];
  currentField: GqlFieldValues;
  affectedItem: ConditionItem | undefined;
  onChange: (item: ConditionItem) => void;
};

export const AffectedItem = ({
  label,
  itemType,
  sections,
  groups,
  fields,
  currentField,
  affectedItem,
  onChange,
}: AffectedItemProps) => {
  const sortedItems = useMemo(() => {
    const sectionsMap = fromEntries(sections.map((s) => [s.uri, s]));
    const groupsMap = fromEntries(groups.map((g) => [g.uri, g]));

    const sortedSections = sortField(sections, { key: "order", dir: "asc" });
    const currentSectionIndex = sortedSections.findIndex(
      (s) => s.uri === currentField.sectionUri,
    );

    const sortedGroupsAndField = sortField(
      prefixOrder(currentField.groupUri ? groups : [currentField, ...groups], [
        (item) => sectionsMap[item.sectionUri]?.order,
      ]),
      { key: "order", dir: "asc" },
    );
    const currentGroupOrFieldIndex = sortedGroupsAndField.findIndex(
      (groupOrField) =>
        groupOrField.uri ===
        (currentField.groupUri ? currentField.groupUri : currentField.uri),
    );

    const sortedFields = sortField(
      prefixOrder(fields, [
        (f) => sectionsMap[f.sectionUri]?.order,
        (f) => groupsMap[f.groupUri || ""]?.order,
      ]),
      { key: "order", dir: "asc" },
    );
    const currentFieldIndex = sortedFields.findIndex(
      (f) => f.uri === currentField.uri,
    );

    return {
      Section: sortedSections.slice(currentSectionIndex + 1),
      Group: sortedGroupsAndField.slice(currentGroupOrFieldIndex + 1),
      Field: sortedFields.slice(currentFieldIndex + 1),
    };
  }, [sections, groups, fields, currentField]);

  const options = itemType ? sortedItems[itemType] : [];

  return (
    <Select
      dataTestId="affected-item"
      label={label}
      options={options.map((item) => ({
        label:
          (item as GqlFieldValues).label ||
          (item as GqlSectionValues).title ||
          "",
        value:
          (item as GqlFieldValues).uri || (item as GqlSectionValues).uri || "",
      }))}
      value={affectedItem?.uri || ""}
      className="flex-1"
      onChange={(selectedValue) => {
        const selectedItem = options.find((item) => item.uri === selectedValue);
        onChange(selectedItem as ConditionItem);
      }}
    />
  );
};
