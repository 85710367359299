import { useMemo, useState } from "react";

import {
  matterTypeProviderId,
  roleProviderId,
} from "@smart/bridge-smokeball-basic";
import { useAsync } from "@smart/itops-hooks-dom";
import {
  arrayComparer,
  filterBy,
  isNullOrUndefined,
  removeEmptyValues,
  removeKeysRecursively,
} from "@smart/itops-utils-basic";
import {
  useQueryMatterFields,
  useQueryMatterLayouts,
  useUpdateField,
  useUpdateGroup,
} from "@smart/manage-gql-client-dom";
import { Gql } from "@smart/manage-gql-operations-dom";
import { useUser } from "@smart/manage-hooks-dom";

import { GqlField, GqlGroup } from "../types";
import { mapFieldOptions, mapFieldType } from "../utils";

export type ItemToUpdate = {
  uri: string;
  layout: GqlGroup["values"]["layout"];
  field: GqlGroup["values"]["field"];
};

const buildRemapLayoutAndField =
  ({
    layouts,
    targetMatterTypeIds,
    queryMatterFields,
    teamUri,
  }: {
    layouts: Awaited<ReturnType<ReturnType<typeof useQueryMatterLayouts>>>;
    targetMatterTypeIds: string[];
    queryMatterFields: ReturnType<typeof useQueryMatterFields>;
    teamUri: string;
  }) =>
  async ({
    values,
  }: {
    values: {
      uri: string;
      layout?: GqlGroup["values"]["layout"];
      field?: GqlGroup["values"]["field"];
    };
  }): Promise<ItemToUpdate | undefined> => {
    if (!values.layout) return undefined;

    if (
      values.layout.id === "MatterType" &&
      values.layout.providerId === matterTypeProviderId
    ) {
      const matterTypeFields = await queryMatterFields({
        matterLayoutId: "MatterType",
        providerId: matterTypeProviderId,
        teamUri,
        matterTypeIds: targetMatterTypeIds,
      });

      const newField = matterTypeFields.find(
        (f) => f.name === values.field?.name,
      );
      const isDifferent =
        values.field?.possibleValues &&
        newField?.possibleValues &&
        !arrayComparer([...values.field.possibleValues], "equalTo", [
          ...newField.possibleValues,
        ]);

      return isDifferent
        ? { uri: values.uri, layout: values.layout, field: newField }
        : undefined;
    }

    const isSameLayout = !!layouts.find((layout) => {
      const isSameId = layout.id === values.layout?.id;
      const isSameParentId = isNullOrUndefined(values.layout?.parentId)
        ? !!values.layout?.parentId === !!layout.parentId
        : values.layout?.parentId === layout.parentId;

      return isSameId && isSameParentId;
    });
    if (isSameLayout) return undefined;

    const layoutNameMatch = layouts.find((layout) => {
      const isSameName = layout.name === values.layout?.name;
      const isSameParentName = isNullOrUndefined(values.layout?.parentName)
        ? !!values.layout?.parentName === !!layout.parentName
        : values.layout?.parentName === layout.parentName;

      return isSameName && isSameParentName;
    });

    if (layoutNameMatch) {
      if (values.layout.providerId === roleProviderId)
        return {
          uri: values.uri,
          layout: layoutNameMatch,
          field: values.field,
        };

      const layoutFields = await queryMatterFields({
        matterLayoutId: layoutNameMatch.id,
        providerId: layoutNameMatch.providerId,
        teamUri,
      });
      const fieldNameMatch = layoutFields.find(
        (f) => f.name === values.field?.name,
      );
      if (fieldNameMatch)
        return {
          uri: values.uri,
          layout: layoutNameMatch,
          field: fieldNameMatch,
        };
    }

    return {
      uri: values.uri,
      layout: undefined,
      field: undefined,
    };
  };

export const useRemapFields = ({
  groups,
  fields,
  currentMatterTypeIds,
  targetMatterTypeIds,
}: {
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
  currentMatterTypeIds: string[];
  targetMatterTypeIds: string[];
}) => {
  const { user } = useUser();
  const [groupsToUpdate, setGroupsToUpdate] = useState<ItemToUpdate[]>([]);
  const [fieldsToUpdate, setFieldsToUpdate] = useState<ItemToUpdate[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [updateGroup] = useUpdateGroup();
  const [updateField] = useUpdateField();
  const queryMatterLayouts = useQueryMatterLayouts();
  const queryMatterFields = useQueryMatterFields();

  const hasSameMatterTypes = useMemo(
    () =>
      arrayComparer([...currentMatterTypeIds], "equalTo", [
        ...targetMatterTypeIds,
      ]),
    [currentMatterTypeIds, targetMatterTypeIds],
  );

  useAsync(async () => {
    if (hasSameMatterTypes || !targetMatterTypeIds.length) {
      setGroupsToUpdate([]);
      setFieldsToUpdate([]);
      return;
    }

    setIsLoading(true);
    const layouts = await queryMatterLayouts({
      matterTypeIds: targetMatterTypeIds,
    });
    const groupRemapResult: (ItemToUpdate | undefined)[] = await Promise.all(
      groups?.map(
        buildRemapLayoutAndField({
          layouts,
          queryMatterFields,
          targetMatterTypeIds,
          teamUri: user?.teamUri || "",
        }),
      ) || [],
    );
    setGroupsToUpdate(groupRemapResult.filter(Boolean) as ItemToUpdate[]);

    const fieldRemapResult: (ItemToUpdate | undefined)[] = await Promise.all(
      fields?.map(
        buildRemapLayoutAndField({
          layouts,
          queryMatterFields,
          targetMatterTypeIds,
          teamUri: user?.teamUri || "",
        }),
      ) || [],
    );
    setFieldsToUpdate(fieldRemapResult.filter(Boolean) as ItemToUpdate[]);

    setIsLoading(false);
  }, [[...targetMatterTypeIds].sort().join("")]);

  const remapFields = async () => {
    if (groupsToUpdate.length) {
      await Promise.all(
        groupsToUpdate.map((g) => {
          const existing = groups?.find((ex) => ex.uri === g.uri);
          if (!existing) return undefined;

          return updateGroup({
            variables: {
              uri: existing.uri,
              formUri: existing.values.formUri,
              sectionUri: existing.values.sectionUri,
              order: existing.values.order,
              fields: {
                ...(removeKeysRecursively(
                  removeEmptyValues(
                    filterBy(existing.values, (key) =>
                      [
                        "allowedRepeatable",
                        "hint",
                        "label",
                        "links",
                        "maxRepeat",
                        "minRepeat",
                        "repeatPrompt",
                        "repeatable",
                        "templateType",
                        "type",
                      ].includes(key),
                    ),
                  ),
                  ["__typename", "displayName", "details"],
                ) as Gql.UpdateGroupMutationVariables["fields"]),
                layout: g.layout
                  ? {
                      id: g.layout.id,
                      name: g.layout.name,
                      parentId: g.layout.parentId || undefined,
                      parentName: g.layout.parentName || undefined,
                      parentProviderId: g.layout.parentProviderId || undefined,
                      providerId: g.layout.providerId,
                    }
                  : undefined,
                field: g.field
                  ? {
                      name: g.field.name,
                      type: g.field.type,
                      possibleValues: g.field.possibleValues || undefined,
                    }
                  : undefined,
              },
            },
          });
        }),
      );
    }
    if (fieldsToUpdate.length) {
      await Promise.all(
        fieldsToUpdate.map((f) => {
          const existing = fields?.find((ex) => ex.uri === f.uri);
          if (!existing) return undefined;

          const type = f.field
            ? mapFieldType(f.field) || existing.values.type
            : existing.values.type;
          const options = f.field?.possibleValues?.length
            ? mapFieldOptions(f.field)
            : {
                options: [],
                allowCustomResponse: false,
              };

          return updateField({
            variables: {
              uri: existing.uri,
              formUri: existing.values.formUri,
              sectionUri: existing.values.sectionUri,
              groupUri: existing.values.groupUri || undefined,
              order: existing.values.order,
              fields: {
                ...(removeKeysRecursively(
                  removeEmptyValues(
                    filterBy(existing.values, (key) =>
                      [
                        "allowCopyFromFieldUri",
                        "availability",
                        "availableStaffIds",
                        "bufferTime",
                        "duration",
                        "hint",
                        "label",
                        "links",
                        "mandatory",
                        "meetingType",
                        "minimumNotice",
                        "timezone",
                      ].includes(key),
                    ),
                  ),
                  ["__typename", "displayName", "details"],
                ) as Gql.UpdateFieldMutationVariables["fields"]),
                type,
                ...options,
                layout: f.layout
                  ? {
                      id: f.layout.id,
                      name: f.layout.name,
                      parentId: f.layout.parentId || undefined,
                      parentName: f.layout.parentName || undefined,
                      parentProviderId: f.layout.parentProviderId || undefined,
                      providerId: f.layout.providerId,
                    }
                  : undefined,
                field: f.field
                  ? {
                      name: f.field.name,
                      type: f.field.type,
                      possibleValues: f.field.possibleValues || undefined,
                    }
                  : undefined,
              },
            },
          });
        }),
      );
    }
  };

  return {
    groupsToUpdate,
    fieldsToUpdate,
    hasSameMatterTypes,
    remapFields,
    isLoading,
  };
};
