import { FC } from "react";

import {
  AffectedBy,
  ConditionListItemProps,
  Effecting,
} from "./condition-list-item";
import { ConditionItem } from "./utils";
import { ItemType, itemTypeLabels } from "../../../types";
import { Condition } from "../conditions";

type ConditionListProps = {
  conditions?: Condition[];
  setCurrentField: (field: ConditionItem | null) => void;
  itemType: ItemType;
};

type ConditionListFactoryProps = {
  title: (itemType: ItemType) => string;
  empty: (itemType: ItemType) => string;
  element: FC<ConditionListItemProps>;
};

const conditionListFactory =
  ({ title, empty, element: Element }: ConditionListFactoryProps) =>
  ({ conditions, setCurrentField, itemType }: ConditionListProps) => (
    <div className="bg-white rounded w-[50%] py-[1.2rem] px-[1.6rem] mb-[1.2rem] min-h-[12.8rem]">
      <p className="text-paragraph font-semibold">
        {conditions?.length ? title(itemType) : empty(itemType)}
      </p>
      {conditions?.map((condition) => (
        <Element
          key={condition.id}
          condition={condition}
          setCurrentField={setCurrentField}
        />
      ))}
    </div>
  );

export const AffectedByList = conditionListFactory({
  empty: (itemType) =>
    `This ${itemTypeLabels[itemType]} is always shown and no conditions affect this ${itemTypeLabels[itemType]}.`,
  title: (itemType) => `This ${itemTypeLabels[itemType]} is affected when:`,
  element: AffectedBy,
});

export const EffectingList = conditionListFactory({
  empty: (itemType) =>
    `This ${itemTypeLabels[itemType]} has no following conditions applied to it.`,
  title: (itemType) => `Answering this ${itemTypeLabels[itemType]} will:`,
  element: Effecting,
});
