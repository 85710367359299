import clsx from "clsx";

import { Locale } from "@smart/bridge-types-basic";

const dateFormatter = {
  day: "dd",
  month: "mm",
  year: "yyyy",
};

export const DateDisplayer = ({
  dateFormat,
}: {
  dateFormat: Locale["dateFormat"];
}) => (
  <div className="flex flex-row gap-small p-2">
    {dateFormat.map((part) => (
      <div className="flex flex-col gap-2" key={part}>
        <div
          className={clsx(
            "flex items-center px-[1.2rem] h-16 border border-neutral-200 rounded bg-neutral-20",
            part === "year" ? "w-[7.2rem]" : "w-[6.4rem]",
          )}
        >
          <span className="text-ui text-neutral-400 pointer-events-none select-none">
            {dateFormatter[part]}
          </span>
        </div>
      </div>
    ))}
  </div>
);
