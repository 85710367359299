import clsx from "clsx";

export const optionContainerClasses =
  "group flex flex-wrap items-center bg-white border border-neutral-200 rounded gap-4 my-[0.4rem] mx-0 py-[0.32rem] pr-[0.8rem] min-h-16";

export const optionItemEditClasses = "flex-1 text-ellipsis overflow-hidden";

export const optionItemIndicatorClasses = (isRadio: boolean) =>
  clsx(
    "transition duration-500 bg-white border border-neutral-200 h-[1.8rem] w-[1.8rem]",
    isRadio ? "rounded-full" : "rounded-small",
  );
