import { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { loadLocale } from "@smart/itops-locale-dom";
import {
  Alert,
  CurrencyInput,
  Select,
} from "@smart/itops-sb-design-system-dom";
import { useLoadBankAccounts } from "@smart/manage-gql-client-dom";

import { GqlFieldValues, OnUpdateField } from "../../types";

type PaymentProps = {
  onUpdateField: OnUpdateField;
  field: GqlFieldValues;
};

export const Payment = ({
  field: fieldValues,
  onUpdateField,
}: PaymentProps) => {
  const bankAccounts = useLoadBankAccounts({});
  const { amountInCents, bankAccount } = fieldValues.payment || {};

  const formMethods = useForm<{
    amountInCents?: number | null;
    bankAccount?: {
      id: string;
      type: number;
    } | null;
  }>({
    defaultValues: {
      amountInCents,
      bankAccount,
    },
  });

  const { control, handleSubmit, getValues, setValue } = formMethods;

  const submit = handleSubmit(async (values) => {
    await onUpdateField({
      field: fieldValues,
      updated: {
        payment: {
          amountInCents: values.amountInCents,
          bankAccount: values.bankAccount,
        },
      },
    });
  });

  useEffect(() => {
    if (bankAccounts.result?.length && !getValues("bankAccount.id")) {
      setValue("bankAccount", bankAccounts.result[0].identifier);
      submit().catch(console.error);
    }
  }, [bankAccounts.result]);

  const disabled = !bankAccounts.loading && !bankAccounts.result?.length;
  const getMessage = () => {
    if (bankAccounts.loading) return "loading...";
    return disabled
      ? "No accounts linked to Smokeball Payments"
      : "Can only be used with Smokeball Payments";
  };

  return (
    <FormProvider {...formMethods}>
      {disabled && (
        <div className="mb-[1.6rem]">
          <Alert
            content="Can only be used with Smokeball Payments"
            variant="section"
            palette="warning"
          />
        </div>
      )}
      <div className="flex flex-col gap-small">
        <Controller
          control={control}
          name="amountInCents"
          render={({ field }) => {
            const locale = loadLocale();

            return (
              <CurrencyInput
                label="Amount"
                intlConfig={locale}
                defaultValue={field.value ? field.value / 100 : undefined}
                onValueChange={(_, __, values) => {
                  if (values?.float) field.onChange(values.float * 100);
                }}
                onBlur={submit}
                disabled={disabled}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="bankAccount"
          render={({ field, fieldState }) => (
            <Select
              id={field.name}
              label="Linked account"
              placeholder="-"
              options={(bankAccounts.result || [])
                .filter((a) => !!a.identifier)
                .map((account) => ({
                  label:
                    account.displayName ||
                    account.name ||
                    `${account.typeDisplayName} - Account`,
                  value: account.identifier,
                }))}
              valueComparer={(currentValue, valueToCompare) =>
                currentValue?.id === valueToCompare?.id
              }
              error={!!fieldState.error}
              message={fieldState.error?.message || getMessage()}
              value={
                // Fall back to null so the input doesn't change between controlled and uncontrolled
                (bankAccounts.result?.find(
                  (account) => account.identifier?.id === field.value?.id,
                )?.identifier || null) as NonNullable<
                  ReturnType<typeof useLoadBankAccounts>["result"]
                >[number]["identifier"]
              }
              onChange={async (identifier) => {
                field.onChange(identifier);
                await submit();
              }}
              disabled={disabled}
            />
          )}
        />
      </div>
    </FormProvider>
  );
};
