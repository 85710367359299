import { useState } from "react";

import { Icon } from "@smart/itops-icons-dom";
import {
  Button,
  ContextMenu,
  Modal,
  SearchInput,
  Select,
  Table,
} from "@smart/itops-sb-design-system-dom";
import { useLazyLoadTeams } from "@smart/manage-gql-client-dom";
import { useManageFormsContext } from "@smart/manage-hooks-dom";

import { ActAs } from "./act-as";
import { useFormColumns } from "./columns";
import { CreatingFormModal } from "./creating";
import { useFormData } from "./data";
import { DeletingFormModal } from "./deleting";
import { DuplicatingFormModal } from "./duplicating";
import { SetupModal } from "./setup";
import { Banner } from "../../shared";
import { ActingAsLabel } from "../shared/acting-as-label";
import { WindowCloser } from "../shared/window-closer";
import { GqlForm, GqlSetting } from "../types";

const styles = {
  heading:
    "flex px-10 py-2 gap-8 items-center justify-between bg-white border-b border-neutral-100",
  page: "m-auto p-10 max-w-screen-maximum",
  container: "flex flex-col w-full bg-white rounded",
  toolbarWrapper: "flex text-ui max-w-screen-maximum",
  toolbar: "flex justify-between flex-1 max-w-screen-maximum mx-auto p-6",
  toolbarFilters: "flex items-center gap-3 flex-wrap",
  toolbarActions: "flex items-start min-w-[140px] gap-4",
};

export type ListFormsProps = {
  loading?: boolean;
  forms?: GqlForm[];
  setting?: GqlSetting;
};

export const ListForms = ({ loading, forms, setting }: ListFormsProps) => {
  const [creatingForm, setCreatingForm] = useState<true>();
  const { data, filterOptions, filters } = useFormData({ forms });
  const { canActAsFirm } = useManageFormsContext();
  const [loadTeam, { data: loadedTeam, error, loading: loadingTeam }] =
    useLazyLoadTeams();
  const [errorLoadingTeam, setErrorLoadingTeam] = useState(error);
  const {
    columns,
    globalFilter,
    select,
    sort,
    deletingForm,
    setDeletingForm,
    duplicatingForm,
    setDuplicatingForm,
  } = useFormColumns();

  return (
    <div className="w-screen min-h-screen bg-neutral-50">
      <div className={styles.heading}>
        <h3 className="my-5 mx-0 font-semibold text-h3 font-poppins">
          Intake Forms
        </h3>
        <div className="flex items-center">
          <ActingAsLabel />
          <WindowCloser />
        </div>
      </div>
      <Banner flagKey="builderBannerInfo" />
      <div className={styles.page}>
        <div className={styles.container}>
          <div className={styles.toolbarWrapper}>
            <div className={styles.toolbar}>
              <div className={styles.toolbarFilters}>
                <SearchInput
                  value={globalFilter?.state}
                  onChange={(e) =>
                    globalFilter?.onChange(e.currentTarget.value)
                  }
                  onClear={() => globalFilter?.onChange("")}
                  placeholder="Search"
                  dataTestId="search-forms-builder"
                  className="w-96"
                />
                {filters.map((f) => (
                  <Select {...f} />
                ))}
                <ContextMenu
                  button={{
                    className: "w-[2rem] h-[2rem]",
                    variant: "secondarySubtle",
                    leftIcon: "regularFilter",
                  }}
                  placement="bottom-end"
                  offsetBy={5}
                  items={filterOptions}
                  id="filter-forms"
                  className="w-fit mx-4"
                />
              </div>
              <div className={styles.toolbarActions}>
                {canActAsFirm && (
                  <ActAs loadTeam={loadTeam} loadedTeam={loadedTeam} />
                )}
                <Button
                  leftIcon="regularPlus"
                  variant="primaryDefault"
                  text="Create form"
                  onClick={() => setCreatingForm(true)}
                />
              </div>
            </div>
          </div>
          <Table
            data={data}
            columns={columns}
            updating={loading || loadingTeam}
            loadingRows={loading && !forms ? 3 : undefined}
            getRowId={(row) => row.uri}
            select={select}
            sort={sort}
            globalFilter={globalFilter}
            empty={
              <>
                <Icon
                  className="empty-icon"
                  library="lucide"
                  name="FilePenLine"
                  size={30}
                  stroke={1.6}
                />
                <p>No forms {forms?.length ? "found" : "available"}.</p>
                <p>To get started, create a form above.</p>
              </>
            }
          />
          <CreatingFormModal
            creatingForm={creatingForm}
            forms={forms || []}
            setting={setting}
            onClose={() => setCreatingForm(undefined)}
          />
          <DeletingFormModal
            deletingForm={deletingForm}
            onClose={() => setDeletingForm(undefined)}
          />
          <DuplicatingFormModal
            duplicatingForm={duplicatingForm}
            forms={forms || []}
            onClose={() => setDuplicatingForm(undefined)}
          />
          <SetupModal loading={loading} setting={setting} />
          <Modal
            header={{
              icon: "solidCircleInfo",
              text: "Error loading firm",
            }}
            onClose={() => setErrorLoadingTeam(undefined)}
            open={!!errorLoadingTeam}
            footer={{
              buttons: [
                {
                  text: "OK",
                  type: "reset",
                  variant: "primaryDefault",
                  onClick: () => setErrorLoadingTeam(undefined),
                },
              ],
            }}
          >
            <p className="text-ui">{`This error occurred when trying to switch firms:\n${errorLoadingTeam}`}</p>
          </Modal>
        </div>
      </div>
    </div>
  );
};
