import { useNavigate } from "react-router-dom";

import { TemplateResponseData } from "@smart/bridge-templates-basic";
import {
  Button,
  ProgressBar,
  SegmentedControl,
} from "@smart/itops-sb-design-system-dom";

import { Actions } from "./actions";
import { FormInfo } from "./form-info";
import { EditingFormInfo } from "../../list-forms/form";
import {
  GqlField,
  GqlFormCategory,
  GqlGroup,
  GqlMatterType,
  GqlSetting,
} from "../../types";

type FormHeadingProps = {
  formUri: string;
  formTitle: string;
  tab?: "questions" | "preview";
  isMatterSpecific: boolean;
  isFormActive: boolean | undefined;
  canActivate?: boolean;
  canUpdate?: boolean;
  disabled?: boolean;
  loading: {
    form: boolean;
    matterTypes: boolean;
  };
  updating?: boolean;
  setTab: React.Dispatch<React.SetStateAction<"questions" | "preview">>;
  onActiveToggleClick: () => Promise<void>;
  matterTypeNames: string[];
  matterTypeLocations: string[];
  onFormTitleUpdate: (title: string) => Promise<void>;
  validateFormTitle: (title: string) => boolean;
  confirmationMessage: string;
  saveConfirmationMessage: (value?: string) => Promise<void>;
  defaultConfirmationMessage?: string | null;
  placeholderData: Partial<TemplateResponseData>;
  updateFormInfo: (values: EditingFormInfo) => Promise<void>;
  setting?: GqlSetting | null;
  category?: GqlFormCategory;
  matterTypes: GqlMatterType[];
  groups?: GqlGroup[] | null;
  fields?: GqlField[] | null;
  previewErrorToggle: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  clearIntakeForm: () => void;
  disableTabs?: boolean;
};

const FormHeading = ({
  formUri,
  formTitle,
  tab,
  isMatterSpecific,
  isFormActive,
  canActivate,
  canUpdate,
  disabled,
  loading,
  updating,
  setTab,
  onActiveToggleClick,
  matterTypeNames,
  matterTypeLocations,
  onFormTitleUpdate,
  validateFormTitle,
  confirmationMessage,
  saveConfirmationMessage,
  defaultConfirmationMessage,
  placeholderData,
  updateFormInfo,
  setting,
  category,
  matterTypes,
  groups,
  fields,
  previewErrorToggle,
  clearIntakeForm,
  disableTabs,
}: FormHeadingProps) => {
  const navigate = useNavigate();

  return (
    <div className="relative pl-[2.4rem] pr-[4rem] py-[0.8rem] border-neutral-100 border-solid border-b">
      {updating && (
        <div className="absolute w-full bottom-0 left-0">
          <ProgressBar />
        </div>
      )}
      <div className="flex items-center gap-[1.3rem]">
        <Button
          dataTestId="back-button"
          leftIcon="regularArrowLeft"
          variant="primarySubtle"
          onClick={() =>
            navigate(`/embedded/${isMatterSpecific ? "tab" : "builder"}`)
          }
        />
        <FormInfo
          formTitle={formTitle}
          matterTypeNames={matterTypeNames}
          matterTypeLocations={matterTypeLocations}
          disabled={disabled}
          isMatterSpecific={isMatterSpecific}
          loading={loading}
          onFormTitleUpdate={onFormTitleUpdate}
          validateFormTitle={validateFormTitle}
          updateFormInfo={updateFormInfo}
          setting={setting}
          category={category}
          matterTypes={matterTypes}
          groups={groups}
          fields={fields}
        />
        <div className="flex justify-center flex-1">
          <SegmentedControl
            index={tab === "questions" ? 0 : 1}
            items={[
              {
                label: "Edit form",
                disabled: disableTabs,
                onClick: () => setTab("questions"),
              },
              {
                label: "Preview form",
                disabled: disableTabs,
                onClick: () => setTab("preview"),
              },
            ]}
          />
        </div>
        <Actions
          formUri={formUri}
          isMatterSpecific={isMatterSpecific}
          isFormActive={isFormActive}
          canActivate={canActivate}
          disabled={disabled}
          loading={loading.form && !formTitle}
          canUpdate={canUpdate}
          onActiveToggleClick={onActiveToggleClick}
          confirmationMessage={confirmationMessage}
          saveConfirmationMessage={saveConfirmationMessage}
          defaultConfirmationMessage={defaultConfirmationMessage}
          placeholderData={placeholderData}
          previewErrorToggle={previewErrorToggle}
          isPreview={tab === "preview"}
          clearIntakeForm={clearIntakeForm}
        />
      </div>
    </div>
  );
};

export { FormHeading, FormHeadingProps };
