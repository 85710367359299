import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  useOrderedSections,
  useVisibility,
} from "@smart/bridge-intake-components-dom";
import { getMatterTypeRepresentatives } from "@smart/bridge-smokeball-basic";
import {
  SubmissionNS,
  familyProQuestionnaireFormUri,
} from "@smart/bridge-types-basic";
import {
  useParamsWithDefault,
  useSearchWithDefault,
} from "@smart/itops-hooks-dom";
import { fromEntries, jsonParseOrReturn } from "@smart/itops-utils-basic";
import {
  MatterSubmission,
  useLoadFields,
  useLoadFormForEdit,
  useLoadGroups,
  useLoadSections,
  useLoadSubmission,
  useUpdateResponse,
  useUpdateSubmission,
  useUploadFiles,
  submissionLink as getSubmissionLink,
  mapToMatterSubmission,
  useLoadStaffDetails,
  useUpdateAIUserFeedback,
} from "@smart/manage-gql-client-dom";
import {
  useLoadFormForDisplay,
  useSyncToMatter,
  useToast,
} from "@smart/manage-hooks-dom";

import { Header } from "./header";
import { Tab } from "./tab";
import { GenerateQRCode } from "../../shared";
import { SubmissionTabOptions } from "../../types";
import { SubmissionActions } from "../actions";
import { SubmissionViewSync } from "../view/sync";

export const SubmissionV2 = () => {
  const { id: submissionId } = useParamsWithDefault((p) => ({
    id: p.id || "",
  }));
  const { tab } = useSearchWithDefault((p) => ({
    tab: (p.get("tab") || "view") as SubmissionTabOptions,
  }));

  const submission = useLoadSubmission({
    uri: SubmissionNS.generateUri(submissionId),
  });
  const navigate = useNavigate();
  const [updateSubmission] = useUpdateSubmission();
  const [updateResponse] = useUpdateResponse();
  const [uploadFiles] = useUploadFiles();
  const { setToasts } = useToast();
  const { sync } = useSyncToMatter();
  const [updateAIUserFeedback] = useUpdateAIUserFeedback();

  const formUri = submission.result?.form.uri || "";
  const submissionUri = submission.result?.uri;

  const { result: form, loading: loadingForm } = useLoadFormForEdit({
    uri: formUri,
    submissionUri,
  });
  const displayProps = useLoadFormForDisplay({ formUri, submissionUri });
  const { result: sectionsResult, loading: loadingSections } = useLoadSections({
    formUri,
    submissionUri,
  });
  const { result: groupsResult, loading: loadingGroups } = useLoadGroups({
    formUri,
    submissionUri,
  });
  const { result: fieldsResult, loading: loadingFields } = useLoadFields({
    formUri,
    submissionUri,
  });

  const staffDetails = useLoadStaffDetails({
    staffIds: [
      ...new Set(
        displayProps.fields.flatMap((field) => field.availableStaffIds ?? []),
      ),
    ],
  });

  const [cancellingSubmission, setCancellingSubmission] =
    useState<MatterSubmission>();
  const [deletingSubmission, setDeletingSubmission] =
    useState<MatterSubmission>();
  const [sendingSubmission, setSendingSubmission] =
    useState<MatterSubmission>();
  const [generatingQRCode, setGeneratingQRCode] = useState<MatterSubmission>();
  const [submissionLink, setSubmissionLink] = useState<string>();
  const [syncing, setSyncing] = useState<MatterSubmission>();
  const [matterSubmission, setMatterSubmission] = useState<MatterSubmission>();

  const groups = groupsResult?.map((g) => g.values) || [];
  const sections = sectionsResult?.map((s) => s.values) || [];
  const fields = fieldsResult?.map((f) => f.values) || [];
  const responses = fromEntries(
    (submission?.result?.responses || []).map((r) => [
      r.fieldUri,
      jsonParseOrReturn(r.value),
    ]),
  );

  const visibility = useVisibility({
    groups,
    sections,
    fields,
    responses,
  });

  const orderedSections = useOrderedSections({
    groups,
    sections,
    fields,
    visibility,
  });

  const navigateTo = (destination: SubmissionTabOptions | "home") => {
    if (destination === "home") navigate("/embedded/tab");
    else navigate(`/embedded/tab/${submissionId}?tab=${destination}`);
  };

  const matterTypes = form?.rawForm.values.matterTypes;

  const formMatterTypeRepresentatives = useMemo(
    () =>
      matterTypes?.length
        ? getMatterTypeRepresentatives(matterTypes)
        : undefined,
    [matterTypes],
  );

  useEffect(() => {
    if (!generatingQRCode) {
      setSubmissionLink(undefined);
    } else {
      setSubmissionLink(
        generatingQRCode.formUri === familyProQuestionnaireFormUri
          ? generatingQRCode.externalSubmissionLink
          : getSubmissionLink(generatingQRCode.rawSubmission),
      );
    }
  }, [generatingQRCode]);

  useEffect(() => {
    if (submission.result) {
      setMatterSubmission(mapToMatterSubmission(submission.result));

      const { syncStatus, aiFillFromMatterInfo } =
        submission.result.values || {};
      if (syncStatus === "loading") {
        submission.startPolling(5000);
      } else if (
        !aiFillFromMatterInfo ||
        submission.result.aiUserFeedback?.values.status === "generated"
      ) {
        submission.stopPolling();
      }
    }
  }, [submission.result]);

  const loading =
    submission.loading ||
    loadingForm ||
    loadingSections ||
    loadingGroups ||
    loadingFields ||
    displayProps.loading ||
    staffDetails.loading ||
    matterSubmission?.syncStatus === "loading";

  return (
    <div className="flex flex-col h-screen">
      <Header
        formTitle={matterSubmission?.formTitle || ""}
        loading={{ form: loading, matterTypes: loadingForm }}
        navigateTo={navigateTo}
        matterSubmission={matterSubmission}
        onCancel={setCancellingSubmission}
        onDelete={setDeletingSubmission}
        onRemind={setSendingSubmission}
        onGenerateQRCode={setGeneratingQRCode}
        setSyncing={setSyncing}
        tab={tab}
        aiFillFromMatterInfo={
          !!submission.data?.submission?.values.aiFillFromMatterInfo
        }
      />
      <Tab
        tab={tab}
        orderedSections={orderedSections}
        responses={responses}
        submission={submission}
        updateResponse={updateResponse}
        updateSubmission={updateSubmission}
        updateAIUserFeedback={updateAIUserFeedback}
        uploadFiles={uploadFiles}
        displayProps={displayProps}
        loading={loading}
        matterSubmission={matterSubmission}
        navigateTo={navigateTo}
        staffDetails={staffDetails.result || []}
      />
      <SubmissionActions
        onViewClose={() => navigateTo("home")}
        cancelling={{
          selected: cancellingSubmission,
          onClose: () => setCancellingSubmission(undefined),
        }}
        deleting={{
          selected: deletingSubmission,
          onClose: () => setDeletingSubmission(undefined),
        }}
        reminding={{
          selected: sendingSubmission,
          onClose: () => setSendingSubmission(undefined),
        }}
        editing={{ selected: undefined, onClose: () => {} }}
      />
      <SubmissionViewSync
        selected={syncing}
        onClose={() => setSyncing(undefined)}
        onConfirm={async (confirmed) => {
          const result = await sync({
            submission: confirmed,
            fields: fieldsResult || [],
            groups: groupsResult || [],
            sections: sectionsResult || [],
            formMatterTypeRepresentatives,
            shouldReviewConflict: true,
          });
          setToasts([
            {
              text:
                result === "pendingContactReview"
                  ? "Pending contact conflict review."
                  : "Responses successfully saved",
            },
          ]);
        }}
      />
      <GenerateQRCode
        link={submissionLink}
        onClose={() => setGeneratingQRCode(undefined)}
        onCopied={() => setToasts([{ text: "QR code copied!" }])}
      />
    </div>
  );
};
